/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { Button, Form, message } from "antd";
import { AuthApi } from "api/auth";
import { IconPrev } from "assets/desktop/homepage/icons";
import InputPasswordMobile from "components/mobile/base/InputPasswordMobile";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { theme } from "styles/theme";

export interface ChangePasswordType {
  username: string;
  oldpass: string;
  password: string;
  passconf: string;
}

export const ChangePassword = () => {
  const navigate = useNavigate();

  const { data: username } = useQuery<string>([QueryKeys.username]);

  const { handleSubmit, control, reset } = useForm<ChangePasswordType>({
    defaultValues: {
      username,
    },
  });

  const onSubmit = async (data: ChangePasswordType) => {
    if (!username) {
      return;
    }

    const response = await AuthApi.changePassword(username, {
      ...data,
    });

    if (response.status === ResponseCode.SUCCESS) {
      message.error({
        content: response.msg,
      });

      reset();
      return;
    }

    message.error({
      content: response.msg,
    });
  };

  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        padding: 15px;
      `}
    >
      <div
        css={css`
          position: relative;
          height: 100%;
        `}
      >
        <div
          css={css`
            position: absolute;
            left: 0;
            top: 50%;
            transform: translatey(-50%);
            path {
              fill: ${theme.colors.black};
            }
          `}
          onClick={() => navigate(-1)}
        >
          <IconPrev />
        </div>

        <div
          css={css`
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            letter-spacing: 0.0125em;
            line-height: 2rem;
          `}
        >
          Reset Password
        </div>
      </div>

      <Form onSubmitCapture={handleSubmit(onSubmit)}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
          `}
        >
          <div>
            <label
              css={css`
                font-size: 14px;
              `}
            >
              Old Password
            </label>
            <InputPasswordMobile control={control} name="oldpass" />
          </div>

          <div>
            <label
              css={css`
                font-size: 14px;
              `}
            >
              New Password
            </label>
            <InputPasswordMobile control={control} name="password" />
          </div>

          <div>
            <label
              css={css`
                font-size: 14px;
              `}
            >
              Confirm password
            </label>
            <InputPasswordMobile control={control} name="passconf" />
          </div>
        </div>

        <Button
          css={css`
            width: 100%;
            background-color: #00d8a0;
            height: 36px;
            margin-top: 4px;
            border-radius: 5px;
            :hover {
              border-color: transparent !important;
            }
          `}
          htmlType="submit"
        >
          <p
            css={css`
              font-size: 14px;
              font-weight: 400;
              color: white;
            `}
          >
            Submit
          </p>
        </Button>
      </Form>
    </div>
  );
};
