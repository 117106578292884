import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const HeaderUserStyle = {
  wrapper: css`
    position: fixed;
    top: 0;

    width: 100%;
    padding: 8px 12px;

    background-color: white;
    z-index: 99;
  `,

  icon: css`
    position: absolute;

    left: 18px;

    height: 16px;
    width: 16px;
    svg {
      path {
        fill: #616161;
      }
    }
  `,

  title: css`
    span {
      color: ${theme.colors.black};
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.25px;
    }
  `,
};
