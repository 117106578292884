/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys";
import { getWithdrawalForm } from "query/withdraw/queryFnsWithdraw";
import { ConfigCss } from "styles/config";

import { RolloverStyle } from "./style";

function Rollover() {
  const { data: username } = useQuery<string>({
    queryKey: [QueryKeys.username],
  });

  const { data } = useQuery({
    queryKey: [QueryKeys.WithDrawForm],
    queryFn: () => getWithdrawalForm(username || ""),
    enabled: !!username,
    refetchInterval: 30 * 1000,
  });

  const pending_rollover = data?.pending_rollover;
  const detail = pending_rollover ? pending_rollover.detail[0] : null;
  if (
    !detail ||
    Number(detail.current_amount?.replace(/\.00$/, "")) >=
      Number(detail.achieve_amount?.replace(/\.00$/, "")) ||
    parseFloat(detail.achieve_amount) === 0
  )
    return (
      <div css={RolloverStyle.index}>
        <div css={[RolloverStyle.progressTurnOver(0), ConfigCss.flexBetween]}>
          <span>No Mission</span>
        </div>
      </div>
    );

  const currentTurnOver = Number(detail.current_amount?.replace(/\.00$/, ""));

  const targeTurnOver = Number(detail.achieve_amount?.replace(/\.00$/, ""));
  const percentTurnOver = (currentTurnOver / targeTurnOver) * 100;

  return (
    <div css={RolloverStyle.index}>
      <div
        css={[
          RolloverStyle.progressTurnOver(percentTurnOver),
          ConfigCss.flexBetween,
        ]}
      >
        <span>Rollover</span>
        <span>
          {currentTurnOver}/{targeTurnOver}
        </span>
      </div>
    </div>
  );
}

export default Rollover;
