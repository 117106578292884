import { css } from "@emotion/react";

export const MenuStyle = {
  index: css`
    background: black;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 0.15vw solid #ffbc00;
  `,
  content: css`
    padding: 0px 38.39px 0px 10vw;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  `,

  wrapMenu: css`
    position: relative;
    .logo {
      cursor: pointer;
      position: absolute;

      bottom: 1.2vw;

      padding: 0.6vw 0;
      width: 8vw;
      max-width: 9vw;
      a {
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
        width: 100%;

        img {
          width: 100%;
          transition-duration: 0.2s;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          object-fit: contain;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
        }
      }
    }
  `,

  listMenu: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 7.7vw;
  `,
  menuItem: (isActive: boolean) => css`
    text-decoration: none;
    display: flex;
    padding: 0.12vw 0.6vw;
    align-items: flex-start;
    background: ${isActive ? "#ffbc00" : "transparent"};
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      background: #ffbc00;
    }

    span {
      color: #fff;
      font-size: 0.8vw;
      font-weight: 400;
      letter-spacing: 0.018vw;
      text-transform: uppercase;
    }
  `,
  promotionIcon: css`
    width: 5.5vw;
    position: relative;
    background-color: blueviolet;
    img {
      width: 5.5vw;
      object-fit: contain;
    }
  `,

  contentButton: (isAuthenticated: boolean) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: ${isAuthenticated ? "calc(-100% * 2.2)" : "0"};
    gap: 10px;

    /* span {
      color: #fff;
      font-size: 0.8vw;
      font-weight: 400;
      letter-spacing: 0.018vw;
      text-transform: uppercase;

      border: 1px solid #ffbc00;
      border-bottom: none;
      padding: 4px 8px;
      cursor: pointer;
      &:hover {
        color: #ffbc00;
      }
    } */

    img {
      cursor: pointer;
    }
  `,
};
