/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Modal } from "antd";
import { useEffect, useState } from "react";

import { SelectType } from "..";
import { listLinks } from "../list-link";
import { ModalDetailStyle } from "./index.style";

type Props = {
  itemLink?: SelectType;
  onClose: () => void;
};

function ModalDetail({ itemLink, onClose }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const dataList = itemLink ? listLinks[itemLink?.key] : [];
  useEffect(() => {
    if (itemLink) {
      setCurrentIndex(itemLink?.index || 0);
    }
  }, [itemLink]);
  if (!itemLink) return null;
  const currentItem = dataList[currentIndex];
  return (
    <Modal
      open={!!itemLink}
      closable={false}
      onCancel={onClose}
      css={css`
        .ant-modal-content {
          background: #0a203b;
          background-image: url("/assets/footer/profile_background.jpg");
          background-position: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          color: #fff;
          display: flex;
          font-size: 0.65vw;
          height: 90vh;
          min-width: 70vw;
          position: relative;
          width: 70vw;
          max-height: calc(100vh - 48px);
          padding: 0 !important;
        }
      `}
      bodyStyle={{
        borderRadius: 0,
      }}
      width={"70vw"}
      footer={<></>}
      centered
    >
      <div css={ModalDetailStyle.index}>
        <div css={ModalDetailStyle.sidebar}>
          <div className="footer-title">{currentItem?.title}</div>
          <div className="footer-menu">
            {dataList.map((item, index) => {
              const isActive = index === currentIndex;
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (isActive) return;
                    setCurrentIndex(index);
                  }}
                  className={`item ${isActive ? "active" : ""}`}
                >
                  <span>{item?.name}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div css={ModalDetailStyle.content}>
          <div className="content">
            <h3 className="content-title">{currentItem?.name}</h3>
            <div
              className="html-content"
              dangerouslySetInnerHTML={{ __html: currentItem?.content }}
            />
          </div>
        </div>
        <div onClick={onClose} className="close-button">
          <img src="/assets/footer/Icon-close.png" alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default ModalDetail;
