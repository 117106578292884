import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const InputCustomStyle = {
  self: css`
    &.ant-input,
    .ant-input {
      width: 100%;
      height: 34px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 10px;
      font-size: 14px !important;
      background-color: ${theme.colors.white};
      border-radius: 4px;
      color: ${theme.colors.black};
      outline: none;
      box-shadow: none;
      font-weight: 400;
      ${media["2sm"]} {
        input {
          box-shadow: none;
        }
      }

      &:-webkit-autofill {
        -webkit-background-clip: text;
      }

      &:focus,
      &:hover {
        border-color: rgba(0, 0, 0, 0.1);
      }
      &-disabled {
        opacity: 0.5;
      }

      &-status-error {
        border-color: var(--error-color) !important;
        &::placeholder {
          color: var(--error-color);
        }
      }
    }
    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
    }
  `,
};
