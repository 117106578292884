import { css } from "@emotion/react";

export const PageHeaderStyle = {
  index: css`
    width: 100vw;
    text-align: center;
    height: 50px;
    z-index: 9;

    .w-30 {
      width: 30.48px;
      padding: 5.56px 2.62px 9.44px 4.86px;
    }
  `,
  content: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
  `,
  title: css`
    font-size: 0.6rem;
    font-weight: 700;
    letter-spacing: 0.0125em;
    line-height: 1rem;
  `,
  buttonBack: css`
    display: flex;
    width: 30.48px;
    padding: 5.56px 2.62px 9.44px 4.86px;
    justify-content: center;
    align-items: flex-start;
    border: 0;
    background: #fff;

    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  `,
};
