/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import Language from "components/common/Language";
import { QueryKeys } from "constants/queryKeys";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleOpenModal } from "helpers/handleModal";
import { useContext, useEffect } from "react";

import HeaderAuthentication from "../HeaderAuthen";
import { HeaderStyle } from "./index.style";

export const Header = () => {
  const { data } = useQuery([QueryKeys.isAuthenticated]);

  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      const element = document.getElementById("header-app");
      if (element) {
        const currentPosition = window.pageYOffset;
        if (currentPosition < 8 || currentPosition > 24) {
          if (currentPosition <= 12) {
            element.classList.remove("scale-min");
          } else {
            element.classList.add("scale-min");
          }
        }
      }
    });
  }, []);

  // if (isLoading) {
  //   <div id="header-app" css={HeaderStyle.index}>
  //     <div className="content" css={HeaderStyle.content}>
  //       <LoadingCustom height="100%" />
  //     </div>
  //   </div>;
  // }
  return (
    <div id="header-app" css={HeaderStyle.index}>
      <div className="content" css={HeaderStyle.content}>
        {data ? (
          <HeaderAuthentication />
        ) : (
          <div className="items" css={HeaderStyle.items}>
            <div
              onClick={() =>
                handleOpenModal(state, dispatch, ModalTypeValue.REGISTER)
              }
              className="register"
            >
              <span>Register</span>
            </div>
            <div
              onClick={() =>
                handleOpenModal(state, dispatch, ModalTypeValue.LOGIN)
              }
              className="login"
            >
              <span>Login</span>
            </div>
            <Language />
          </div>
        )}
      </div>
    </div>
  );
};
