import { ProviderGameType, SubGameType } from "types/game";

export const ItemProviderHotGame: ProviderGameType = {
  provider_name: "HOT",
  provider_code: "HOT",
  game_type: "LC",
};

export const providerLive: ProviderGameType[] = [
  {
    provider_name: "Ezugi",
    provider_code: "EZ",
    game_type: "LC",
  },
  {
    provider_name: "Evolution Gaming",
    provider_code: "EVO",
    game_type: "LC",
  },
  {
    provider_name: "PlayTech",
    provider_code: "PT",
    game_type: "LC",
  },
  {
    provider_name: "Sexy Baccarat",
    provider_code: "SX",
    game_type: "LC",
  },
  {
    provider_name: "Super Spade Game",
    provider_code: "SG",
    game_type: "LC",
  },

  {
    provider_name: "MicroGaming",
    provider_code: "MG",
    game_type: "LC",
  },
];

export const ListSubGame: SubGameType[] = [
  {
    provider_name: "Ezugi",
    provider_code: "EZ",
    type: "LC",
    name: "Ezugi",
    picture: "/assets/game/live-casino/ezugi.jpeg",
    new_arrive: "NO",
    featured: "NO",
    game_code: "",
  },
  {
    provider_name: "Evolution Gaming",
    provider_code: "EVO",
    type: "LC",
    name: "Evolution Gaming",
    picture: "/assets/game/live-casino/evolution-gaming.png",
    new_arrive: "NO",
    featured: "NO",
    game_code: "",
  },
  {
    provider_name: "PlayTech",
    provider_code: "PT",
    type: "LC",
    name: "PlayTech",
    picture: "/assets/game/live-casino/playTech.png",
    new_arrive: "NO",
    featured: "NO",
    game_code: "",
  },
  {
    provider_name: "Sexy Baccarat",
    provider_code: "SX",
    type: "LC",
    name: "Sexy Baccarat",
    picture: "/assets/game/live-casino/sexy-baccarat.jpeg",
    new_arrive: "NO",
    featured: "NO",
    game_code: "",
  },
  {
    provider_name: "Super Spade Game",
    provider_code: "SG",
    type: "LC",
    name: "Super Spade Game",
    picture: "/assets/game/live-casino/super-spade-game.jpeg",
    new_arrive: "NO",
    featured: "NO",
    game_code: "",
  },

  {
    provider_name: "MicroGaming",
    provider_code: "MG",
    type: "LC",
    name: "MicroGaming",
    picture: "/assets/game/live-casino/microGaming.jpeg",
    new_arrive: "NO",
    featured: "NO",
    game_code: "",
  },
];
