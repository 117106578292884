import { css } from "@emotion/react";

export const HeaderStyle = {
  index: css`
    background: black;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.scale-min {
      .logo {
        width: 6vw;
      }

      .content {
        padding: 0 1.8vw;
      }

      .items {
        .register {
          padding: 0.15vw 0.55vw;
        }
        .login {
          padding: 0.15vw 0.55vw;
        }
      }
      .language {
        padding: 0.15vw 0.55vw;
      }
    }
  `,
  content: css`
    max-width: 1700px;
    padding: 0 2vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `,
  logo: css`
    cursor: pointer;
    padding: 0.6vw 0;
    width: 7vw;
    position: relative;
    max-width: 80px;
    a {
      display: inline-block;
      overflow: hidden;
      vertical-align: middle;
      width: 100%;

      img {
        width: 100%;
        transition-duration: 0.2s;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        object-fit: contain;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }
    }
  `,
  items: css`
    align-items: center;
    display: flex;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    gap: 0.2vw;

    .register {
      background-color: #ffbc00;
      display: flex;
      padding: 0.18vw 0.6vw;
      justify-content: center;
      align-items: flex-start;
      cursor: pointer;
      transition-duration: 0.2s;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

      span {
        color: #fff;
        text-align: center;
        font-size: 0.8vw;
        font-weight: 400;
        letter-spacing: 0.018vw;
      }
    }

    .login {
      display: flex;
      padding: 0.18vw 0.6vw;
      justify-content: center;
      align-items: flex-start;
      border: 1px solid #ffbc00;
      cursor: pointer;
      transition-duration: 0.2s;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

      span {
        color: #fff;
        text-align: center;
        font-size: 0.8vw;
        font-weight: 400;
        letter-spacing: 0.018vw;
      }
    }
  `,

  headerIsAuthen: css``,
};
