import { css } from "@emotion/react";

export const GameProviderStyle = {
  index: (length: number) => css`
    color: #fff;
    font-size: 0.8vw;
    max-height: 1.9vw;
    z-index: 10;
    margin-bottom: ${length > 10 ? "2vw" : 0};
  `,
  list: css`
    display: flex;
    justify-content: center;
    list-style: none;
    flex-wrap: wrap;
    row-gap: 0.5vw;
    border-bottom: 0.15vw solid #ffbc00;
  `,
  item: css`
    background: #0a203b;
    display: flex;

    .link {
      color: #fff;
      cursor: pointer;
      display: block;
      font-weight: 300;
      letter-spacing: 0.018vw;
      padding: 0.3vw 1.5vw;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        background-color: #ffbc00;
      }
    }

    .transition-all {
      transition-duration: 0.2s;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    .selected {
      background: #ffbc00;
    }
  `,
};
