/** @jsxImportSource @emotion/react */
import { message } from "antd";
import { gameApi } from "api/game";
import { ResponseCode } from "constants/response";
import { ItemProviderHotGame } from "pages/mobile/GamePage/LiveCasino/list-game";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GameListProvider } from "types/game";

import { ProviderGameStyle } from "./index.style";

type Props = {
  gameType?: string;
};
const initDataGameList: GameListProvider = {
  msg: "",
  status: 0,
  dataGameProvider: [],
};
function ProviderGame({ gameType = "LC" }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [dataGameProvider, setDataGameProvider] =
    useState<GameListProvider>(initDataGameList);

  useEffect(() => {
    if (params.provider_code) return;
    if (!dataGameProvider?.dataGameProvider?.length) return;
    navigate(
      `${pathname}/${
        [ItemProviderHotGame, ...(dataGameProvider?.dataGameProvider || [])][0]
          .provider_code
      }`,
      { replace: true },
    );
  }, [dataGameProvider?.dataGameProvider]);

  useEffect(() => {
    if (!gameType) return;

    getGameList();
  }, [gameType]);

  const getGameList = async () => {
    const response = await gameApi.getGameList(gameType);
    if (response.data.status !== ResponseCode.SUCCESS) {
      message.destroy();
      message.error(response.data.msg);
      setDataGameProvider(initDataGameList);
      return;
    }

    setDataGameProvider({
      ...response.data,
      dataGameProvider: response.data.data,
    });
  };

  const handleListProvider = () => {
    // if (pathname.includes("table-games")) {
    //   const listProvider = dataGameProvider?.dataGameProvider?.filter(
    //     (itemFilter) => itemFilter.provider_code !== "T1G",
    //   );
    //   return listProvider;
    // }
    // if (pathname.includes("crash-games")) {
    //   const listProvider = dataGameProvider?.dataGameProvider?.filter(
    //     (itemFilter) => itemFilter.provider_code === "T1G",
    //   );
    //   return listProvider;
    // }
    if (gameType === "SL") {
      const listProvider = dataGameProvider?.dataGameProvider?.filter(
        (itemFilter) =>
          itemFilter.provider_code !== "AVIA" &&
          itemFilter.provider_code !== "SPRI",
      );
      return listProvider;
    }
    return dataGameProvider?.dataGameProvider;
  };
  const listGameProvider = handleListProvider() || [];

  return (
    <div css={ProviderGameStyle.index}>
      <div css={ProviderGameStyle.content}>
        {[ItemProviderHotGame, ...listGameProvider].map((item, index) => {
          const isActive = params.provider_code === item.provider_code;
          return (
            <div
              onClick={() => {
                const links = pathname.split("/");
                navigate(`/${links[1]}/${item.provider_code}`, {
                  replace: true,
                });
              }}
              css={ProviderGameStyle.item(isActive)}
              key={index}
            >
              <span>{item.provider_name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProviderGame;
