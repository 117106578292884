/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Modal } from "components/common/Modal";
import { Footer } from "components/mobile/ui/Footer";
import { Outlet } from "react-router-dom";

export const DefaultLayout = () => {
  return (
    <div css={css``}>
      <main css={css``}>
        <Outlet />
      </main>
      <Footer />
      <Modal />
    </div>
  );
};
