import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ModalUserNickNameStyle = {
  wrapper: css`
    .ant-modal {
      &-content {
        padding: unset !important;
      }
      &-close {
        top: 4px;
        right: 4px;
        &-x {
          color: ${theme.colors.black};
        }
      }
    }
  `,

  mainContent: css`
    padding: 40px 20px 20px 20px;
  `,

  form: css`
    margin-top: 8px;
  `,

  btn: css`
    margin-top: 48px;

    padding: 0 10px;
    button {
      background-color: #ff1900;
      width: 100%;
      span {
        color: ${theme.colors.white};
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.865px;
      }
    }
  `,

  title: css`
    color: ${theme.colors.black};
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.1px;
  `,
};
