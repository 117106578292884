import { css } from "@emotion/react";

export const ModalDetailStyle = {
  index: css`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    position: relative;

    .close-button {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;

      img {
        width: 1.5vw;
        height: 1.5vw;
      }
    }
  `,
  sidebar: css`
    background: #142c4a80;
    height: 100%;
    width: 18vw;
    font-size: 0.65vw;

    .footer-title {
      font-weight: 700;
      padding: 1vw 1.5vw;
      text-transform: uppercase;
    }
    .footer-menu {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .item {
        background: #1e345180;
        color: #7fadeb;
        cursor: pointer;
        padding: 0.75vw 2.5vw;

        &.active {
          background: #2c4c77;
          border-left: 6px solid #ffbc00;
          color: #fff;
          padding-left: 2.2vw;
        }
      }
    }
  `,
  content: css`
    align-self: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0;
    width: calc(70vw - 18vw);

    .content {
      background: #1c3657d6;
      border-radius: 0.3vw;
      display: flex;
      flex-direction: column;
      gap: 1vw;
      height: 92%;
      margin: 3%;
      overflow: hidden;
      padding: 1vw 3vw;
      width: 100%;

      .content-title {
        border-bottom: 1px solid #fff;
        font-size: 1.2vw;
        font-weight: 700;
        line-height: 1.5vw;
        margin: 0;
        padding-bottom: 0.3vw;
        width: 100%;
      }

      .html-content {
        height: max-content;
        overflow: auto;
        padding-right: 12px;
        &::-webkit-scrollbar-thumb {
          background-color: #ffbc00;
        }
        &::-webkit-scrollbar {
          width: 0.2rem;
        }
        &::-webkit-scrollbar-track {
          background-color: initial;
        }

        .body {
          font-size: 0.7vw;
          line-height: 1.2vw;
          overflow-y: auto;

          .text-bold {
            color: #ffbc00;
            font-size: 0.7vw;
            letter-spacing: 0.05vw;
            margin-bottom: 0.25vw;
            text-transform: uppercase;
          }

          ul {
            list-style: disc;
            list-style-position: outside;
            padding: 0.25vw 0 1vw 2vw;
          }
          ol {
            display: block;
            list-style-type: decimal;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;
          }

          &.about {
            display: flex;
            gap: 2vw;
            padding: 2vw 0;

            img {
              object-fit: cover;
              object-position: 50% 50%;
              width: 100%;
            }
          }
        }
      }
    }
  `,
};
