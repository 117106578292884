/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { Logo } from "assets/common/images";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { EnumItemMenuUserModal } from "enums/user";
import {
  handleKeyModal,
  handleOpenKeyItemModal,
  handleOpenModal,
} from "helpers/handleModal";
import { queryClient } from "index";
import { getWithdrawalForm } from "query/withdraw/queryFnsWithdraw";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { MenuStyle } from "./index.style";
import { ListMenus } from "./list-menu";

function Menu() {
  const { pathname } = useLocation();

  const { state, dispatch } = useContext(AppContext);
  const { data: isAuthenticated } = useQuery<boolean>([
    QueryKeys.isAuthenticated,
  ]);
  const { data: username } = useQuery([QueryKeys.username]);

  const navigate = useNavigate();

  return (
    <div css={MenuStyle.index}>
      <div css={MenuStyle.content}>
        <div css={MenuStyle.wrapMenu}>
          <div className="logo">
            <Link
              to={"/"}
              onClick={() => {
                window.scroll({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div css={MenuStyle.listMenu}>
            {ListMenus.map((item, index) => {
              const isActive = item.is_included
                ? pathname.includes(item.link)
                : pathname === item.link;
              return (
                <Link
                  css={MenuStyle.menuItem(isActive)}
                  key={`item-menu-${index}`}
                  to={isActive ? "#" : item.link}
                >
                  <span>{item.name}</span>
                </Link>
              );
            })}
          </div>
        </div>

        <div css={MenuStyle.promotionIcon}>
          <div css={MenuStyle.contentButton(isAuthenticated || false)}>
            {isAuthenticated && (
              <>
                <img
                  src="/assets/app/deposit.png"
                  alt=""
                  onClick={() => {
                    handleOpenModal(state, dispatch, ModalTypeValue.USER);
                    handleKeyModal(
                      state,
                      dispatch,
                      EnumItemMenuUserModal.myWallet,
                    );
                    handleOpenKeyItemModal(
                      state,
                      dispatch,
                      EnumItemMenuUserModal.deposit,
                    );
                  }}
                />
                <img
                  src="/assets/app/withdrawal.png"
                  alt=""
                  onClick={async () => {
                    await queryClient.prefetchQuery({
                      queryKey: [QueryKeys.WithDrawForm],
                      queryFn: () => getWithdrawalForm(username as string),
                    });
                    handleOpenModal(state, dispatch, ModalTypeValue.USER);
                    handleKeyModal(
                      state,
                      dispatch,
                      EnumItemMenuUserModal.myWallet,
                    );
                    handleOpenKeyItemModal(
                      state,
                      dispatch,
                      EnumItemMenuUserModal.withdraw,
                    );
                  }}
                />
              </>
            )}

            <img
              src="/assets/app/promotion-menu.png"
              alt=""
              onClick={() => navigate(PATH.Promotions)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
