import { css } from "@emotion/react";

export const MBankEnrollStyle = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 63px 15px 15px;
  `,

  heading: css`
    display: flex;
    flex-direction: column;
    label {
      font-size: 13px;
      font-weight: 700;
    }
  `,

  btn: css`
    background-color: rgb(39, 216, 160);
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    color: white !important;

    :hover {
      border-color: transparent !important;
    }
  `,
};
