import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const EditPhoneUserStyle = {
  wrapper: css`
    padding: 55px 24px 0 24px;

    h3 {
      color: ${theme.colors.black};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.15px;
    }
  `,

  numberPhone: css`
    span {
      color: #ff1900;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.15px;
    }
  `,

  contact: css`
    margin-top: 48px;
    p {
      color: ${theme.colors.black};
      font-size: 10.7px;
      font-style: normal;
      font-weight: 300;
      line-height: 17.55px;

      padding-bottom: 16.54px;
    }
    a {
      text-decoration: none;
      color: #2196f3;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.5px;
    }
  `,
};
