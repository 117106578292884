/** @jsxImportSource @emotion/react */

import { formatNumberWithCommas } from "helpers/formatNumber";
import { useEffect, useState } from "react";

import { JackPotStyle } from "./style.index";

const JackPot = () => {
  const [number, setNumber] = useState(1855157);
  useEffect(() => {
    setInterval(() => {
      setNumber((prev) => prev + Math.random() * 0.12);
    }, 50);
  }, []);
  return (
    <div css={JackPotStyle.jackpot.index}>
      <div css={JackPotStyle.jackpot.title}>JEETO7 MYSTERY JACKPOT</div>
      <div css={JackPotStyle.jackpot.amount}>
        USD {formatNumberWithCommas(number)}
      </div>
    </div>
  );
};

export default JackPot;
