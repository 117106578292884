/** @jsxImportSource @emotion/react */

import { Empty, message } from "antd";
import { gameApi } from "api/game";
import HelmetCustom from "components/common/Helmet";
import HideFooter from "components/mobile/HideFooter";
import LoadingGame from "components/mobile/LoadingGame";
import PageHeader from "components/mobile/PageHeaderAffiliate";
import ProviderGame from "components/mobile/ProviderGame";
import SubGameList from "components/mobile/SubGameList";
import { ResponseCode } from "constants/response";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { SubGameResponse } from "types/game";

import { CrashStyle } from "./index.style";
import { HotCrashGame } from "./list-game";

const initData: SubGameResponse = {
  data: [],
  totalCount: 0,
  totalPages: 0,
  status: 0,
  msg: "",
  currentPage: 0,
};

function CrashGame() {
  const params = useParams();
  const [data, setData] = useState<SubGameResponse>(initData);
  const [loading, setLoading] = useState<boolean>(false);
  const myRef = useRef(null);

  useEffect(() => {
    if (!params.provider_code) return;
    setData({ ...data, currentPage: 0 });
    getDataSubGame(1);
  }, [params.provider_code]);
  const getDataSubGame = async (page: number) => {
    setLoading(true);
    const providerCode =
      params.provider_code === HotCrashGame.provider_code
        ? ""
        : params.provider_code;

    const featured =
      params.provider_code === HotCrashGame.provider_code ? "1" : "";
    const res = await gameApi.getSubGameList({
      page: page,
      size: 30,
      game_type: HotCrashGame.game_type,
      provider: providerCode,
      featured,
    });

    if (res.data.status !== ResponseCode.SUCCESS) {
      message.destroy();
      message.error(res.data.msg);
      setLoading(false);
      return;
    }
    setData({
      ...res.data,
      data: page > 1 ? [...data.data, ...res.data.data] : res.data.data,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (myRef.current === null) return;
    (myRef.current as any).scrollTo(0, 0);
  }, [myRef.current, params]);

  const onLoadMore = () => {
    getDataSubGame(data.currentPage + 1);
  };

  return (
    <div css={CrashStyle.index}>
      <div css={CrashStyle.header}>
        <HelmetCustom title="Slot Games" />
        <PageHeader title="Slot Games" />
        <ProviderGame gameType="BG" />
      </div>
      <div id="SubGame-list" css={CrashStyle.content} ref={myRef}>
        <InfiniteScroll
          hasMore={data.data.length > 0 && data.data.length < data.totalCount}
          dataLength={data.data.length}
          next={onLoadMore}
          endMessage={""}
          loader={
            <h4 style={{ textAlign: "center", marginTop: "6px" }}>
              Loading...
            </h4>
          }
          scrollableTarget="SubGame-list"
        >
          <SubGameList games={data.data} />
        </InfiniteScroll>
        {!data.data.length && data.currentPage > 0 && (
          <div>
            <Empty />
          </div>
        )}
      </div>
      <HideFooter />
      <LoadingGame visible={data.currentPage > 0 ? false : loading} />
    </div>
  );
}

export default CrashGame;
