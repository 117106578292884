/** @jsxImportSource @emotion/react */

import { QRCode } from "antd";

import { QRDownloadStyle } from "./style.index";

const QRDownload = () => {
  return (
    <div css={QRDownloadStyle.wrapper}>
      <div css={QRDownloadStyle.imageHeader}>
        <img src="/assets/footer/cta_dl.png" alt="" />
      </div>
      <div css={QRDownloadStyle.mainContent}>
        <img
          css={QRDownloadStyle.imageBg}
          src="/assets/footer/footer-top-bg.png"
          alt=""
        />
        <div css={QRDownloadStyle.qrCode}>
          <p className="title">Jeeto7 App</p>
          <p className="text">Bet the whole world on your phone</p>
          <div css={QRDownloadStyle.qrBox}>
            <div css={QRDownloadStyle.qrItem}>
              <QRCode
                size={300}
                value={`${window.location.origin}/app/jeeto7.apk`}
              />
              <p>Android</p>
            </div>
            <div css={QRDownloadStyle.qrItem}>
              <QRCode
                size={300}
                value={`${window.location.origin}/app/jeeto7.mobileconfig`}
              />
              <p>IOS</p>
            </div>
          </div>
          <p className="text">Download App</p>
        </div>
      </div>
      <div css={QRDownloadStyle.imageSide}>
        <img src="/assets/footer/cta_cp.png" alt="" />
      </div>
    </div>
  );
};

export default QRDownload;
