/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { Dropdown, MenuProps } from "antd";
import { AvatarUser, MessageImage } from "assets/common/images";
import * as DropdownIcon from "assets/desktop/dropdown";
import Language from "components/common/Language";
import { QueryKeys } from "constants/queryKeys";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { EnumItemMenuUserModal } from "enums/user";
import { handleLogout } from "helpers/handleLogout";
import {
  handleCloseModal,
  handleKeyModal,
  handleOpenKeyItemModal,
  handleOpenModal,
} from "helpers/handleModal";
import { queryClient } from "index";
import { useGetMemberBalance, useGetProfile } from "query/users/queryHookUser";
import { getWithdrawalForm } from "query/withdraw/queryFnsWithdraw";
import { useContext } from "react";
import { ProfileType } from "types/user";

import { HeaderAuthenticationStyle } from "./style.index";

const HeaderAuthentication = () => {
  const { state, dispatch } = useContext(AppContext);

  const { data: username } = useQuery<string>([QueryKeys.username]);
  const { data: dataProfile } = useGetProfile(username || "");

  const { data: balanceMember } = useGetMemberBalance(username || "");

  const handleLogoutEvent = () => {
    handleCloseModal(state, dispatch);
    handleLogout(dispatch, state);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.profile);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.profileData,
            );
          }}
          css={HeaderAuthenticationStyle.dropdownItem}
        >
          <img src={DropdownIcon.DropdownIconUser} alt="" />
          Edit Profile
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          css={HeaderAuthenticationStyle.dropdownItem}
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.myWallet);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.deposit,
            );
          }}
        >
          <img src={DropdownIcon.DropdownIconDeposit} alt="" />
          Deposit
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          css={HeaderAuthenticationStyle.dropdownItem}
          onClick={async () => {
            await queryClient.prefetchQuery({
              queryKey: [QueryKeys.WithDrawForm],
              queryFn: () => getWithdrawalForm(username as string),
            });
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.myWallet);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.withdraw,
            );
          }}
        >
          <img src={DropdownIcon.DropdownIconWithdraw} alt="" />
          Withdraw
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          css={HeaderAuthenticationStyle.dropdownItem}
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.close);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.vipDetails,
            );
          }}
        >
          <img src={DropdownIcon.DropdownIconVipDetails} alt="" />
          Vip Details
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.close);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.notification,
            );
          }}
          css={HeaderAuthenticationStyle.dropdownItem}
        >
          <img src={DropdownIcon.DropdownIconMessage} alt="" />
          Messages
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <div
          onClick={() => handleLogoutEvent()}
          css={HeaderAuthenticationStyle.dropdownItem}
        >
          <img src={DropdownIcon.DropdownIconLogout} alt="" />
          Logout
        </div>
      ),
    },
  ];

  return (
    <div css={HeaderAuthenticationStyle.wrapper}>
      <span css={HeaderAuthenticationStyle.balance}>
        {balanceMember?.total || 0.0}
      </span>
      <div css={HeaderAuthenticationStyle.avatar}>
        <Dropdown placement="top" menu={{ items }}>
          <img
            src={(dataProfile as ProfileType)?.avatar || AvatarUser}
            alt=""
            onClick={() => {
              handleOpenModal(state, dispatch, ModalTypeValue.USER);
              handleKeyModal(state, dispatch, EnumItemMenuUserModal.profile);
              handleOpenKeyItemModal(
                state,
                dispatch,
                EnumItemMenuUserModal.profileData,
              );
            }}
          />
        </Dropdown>
      </div>
      <span css={HeaderAuthenticationStyle.balance}>{username || ""}</span>
      <div css={HeaderAuthenticationStyle.message}>
        <img
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.close);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.notification,
            );
          }}
          src={MessageImage}
          alt=""
        />
      </div>
      <Language />
    </div>
  );
};

export default HeaderAuthentication;
