/** @jsxImportSource @emotion/react */

import { LanguageStyle } from "./style.index";

const Language = () => {
  return (
    <div className="language" css={LanguageStyle.wrapper}>
      <span className="flags"></span>
      <span className="name">IN-EN</span>
    </div>
  );
};

export default Language;
