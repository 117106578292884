import { css } from "@emotion/react";

export const QRDownloadStyle = {
  wrapper: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5vw;
    position: relative;
    padding-bottom: 1.5vw;
  `,

  mainContent: css`
    position: relative;
  `,

  imageHeader: css`
    background: #0000;
    margin: 2.5vw 7vw;
    width: 55%;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;

    img {
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
    }
  `,

  imageBg: css`
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition: opacity 0.28s ease-in;
  `,

  qrCode: css`
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 0.65vw;
    left: 33%;
    letter-spacing: 0.05vw;
    position: absolute;
    row-gap: 0.6vw;
    top: 40%;
    transform: translate(-50%, -50%);

    .title {
      font-size: 1.5vw;
      font-weight: 700;
    }

    .ant-qrcode {
      background: #fff !important;
      width: 7vw !important;
      height: 7vw !important;

      canvas {
        width: 7vw !important;
        height: 7vw !important;
      }
    }
  `,

  qrItem: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;

    p {
      text-transform: uppercase;
      font-weight: bold;
    }
  `,

  qrBox: css`
    display: flex;
    align-items: center;

    gap: 50px;
  `,

  imageSide: css`
    background: #0000;
    height: auto;
    margin: 0 65%;
    position: absolute;
    width: 14%;

    img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  `,
};
