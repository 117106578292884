/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { Button, Form, message, Modal } from "antd";
import { MemberDetailType } from "api/auth/type";
import { UserApi } from "api/user";
import LoadingCustom from "components/common/LoadingCustom";
import InputCustomMobile from "components/mobile/base/InputCustomMobile";
import { isMobile } from "constants/common";
import { QueryKeys } from "constants/queryKeys";
import { REGEX_FULLNAME } from "constants/regex";
import { ResponseCode } from "constants/response";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleCloseModal } from "helpers/handleModal";
import { useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { ModalUserNickNameStyle } from "./style.index";

interface FormType {
  nickName: string;
}
const ModalUserNickName = () => {
  const { state, dispatch } = useContext(AppContext);

  const { data: dataUserDetail, isLoading } = useQuery<MemberDetailType>([
    QueryKeys.MemberDetail,
  ]);

  const { handleSubmit, control, getValues } = useForm<FormType>({
    mode: "all",
  });

  const validateForm = () => {
    const form = getValues();

    if (!form.nickName?.length) {
      message.info({
        key: "profileData",
        content: "Nick Name must be filled",
      });
      return true;
    }
  };

  const onSubmit: SubmitHandler<FormType> = async (data: FormType) => {
    const isValidate = validateForm();

    if (isValidate) return;

    const { status, msg } = await UserApi.updateProfile(
      dataUserDetail?.username || "",
      {
        username: dataUserDetail?.username || "",
        nickname: data.nickName,
      },
    );

    if (status !== ResponseCode.SUCCESS) {
      message.info({
        key: "Error",
        content: msg,
      });
    } else {
      message.info({
        key: "memberupdate",
        content: "Update successful",
      });
    }
  };

  const handleInputFullNameChange = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const key = event.key;

    if (!REGEX_FULLNAME.test(key) && key !== "Enter") {
      event.preventDefault();
    }
  };

  if (isLoading && !isMobile) {
    return <LoadingCustom height="100%" />;
  }

  return (
    <Modal
      open={state.openModal === ModalTypeValue.USER_NICKNAME}
      onCancel={() => handleCloseModal(state, dispatch)}
      css={ModalUserNickNameStyle.wrapper}
      footer={null}
      centered
      width={312}
    >
      <div css={ModalUserNickNameStyle.mainContent}>
        <div css={ModalUserNickNameStyle.title}>Nick Name</div>
        <Form
          onSubmitCapture={handleSubmit(onSubmit)}
          css={ModalUserNickNameStyle.form}
        >
          <InputCustomMobile
            placeholder={"Please enter nickname"}
            name={"nickName"}
            control={control}
            id={"nickName"}
            defaultValue={dataUserDetail?.nickname}
            onKeyDown={handleInputFullNameChange}
          />

          <div css={ModalUserNickNameStyle.btn}>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalUserNickName;
