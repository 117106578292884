import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ProfileUserStyle = {
  wrapper: css`
    padding: 48px 16px 0 16px;
  `,

  header: {
    index: css`
      text-align: center;
      margin-top: 25.3px;
      p {
        color: ${theme.colors.black};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.15px;
      }
      span {
        font-size: 12px;
      }
    `,
    vip: css`
      margin-top: 4px;
      span {
        font-size: 10px;
        color: ${theme.colors.white};
        text-transform: uppercase;
        border-radius: 4px;

        padding: 2px 6px;
        background-color: #ff1a00;
      }
    `,
  },

  progress: (percent?: number) => css`
    overflow: hidden;

    margin-top: 28px;
    border-radius: 20px;

    height: 32px;
    width: 100%;

    background-color: #d3d3d3;

    position: relative;
    &::before {
      content: "";
      width: ${percent}%;
      height: 100%;
      background: #3119ff;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 20px;
    }

    span {
      position: absolute;
      font-size: 14px;
      color: ${theme.colors.white};
      font-weight: 700;
      line-height: 0;

      top: 50%;

      transform: translateY(-50%);
    }
  `,

  vipProgress: {
    lv: css`
      left: 6px;
    `,
    total: css`
      right: 6px;
    `,
  },

  describe: css`
    text-align: right;

    padding: 15px 16.78px 9px 0;
    color: #9e9e9e;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
  `,
};
