/** @jsxImportSource @emotion/react */

import { IconArrowBack } from "assets/mobile/icons/profile";
import { useNavigate } from "react-router-dom";
import { ConfigCss } from "styles/config";

import { HeaderUserStyle } from "./style.index";

type Props = {
  title: string;
  onBack?: () => void;
};

const HeaderUser = ({ title, onBack }: Props) => {
  const navigate = useNavigate();
  return (
    <div css={[HeaderUserStyle.wrapper, ConfigCss.flexCenter]}>
      <div
        onClick={() => (onBack ? onBack() : navigate(-1))}
        css={HeaderUserStyle.icon}
      >
        <IconArrowBack />
      </div>
      <div css={HeaderUserStyle.title}>
        <span>{title}</span>
      </div>
    </div>
  );
};

export default HeaderUser;
