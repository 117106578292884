/** @jsxImportSource @emotion/react */

import HideFooter from "components/mobile/HideFooter";

import { PrivacyStyle } from "./style.index";

const Privacy = () => {
  return (
    <>
      <div css={PrivacyStyle.wrapper}>
        <h1>Jeeto7 Privacy policy</h1>
        <h2>Privacy Policy</h2>
        <p>
          This privacy policy clarifies how Teen Patti Champion (also referred
          as 3 Patti, we, us, our, the game, this game in this document) treats
          personal information that collected when you register.
        </p>
        <p>
          Your privacy is extremely vital to us and this privacy policy is
          designed to prevent your personal information and provide a safe
          gaming environment. We will explain about the information we collect,
          how we use it, in what condition we may share it, as well as the
          restrictions and changes of this policy.
        </p>
        <p>
          By accepting our Private Policy, you consent to our collection, use
          and disclose of your information as described.
        </p>
        <h2>Information We Collect</h2>
        <p>
          Teen Patti Champion provides players to play as guest or login through
          Facebook. When you register for obtaining the service through
          Facebook, you allow us to access the information you provide or
          consent to provide for that site, but it is affected by the privacy
          settings you and your friends establish in Facebook. You can check out
          your settings before you register the game.
        </p>
        <p>
          We may collect, but is not limited to: your full name, country,
          gender, birthday, device information, profile picture, Facebook
          friends list, login email you gave when you registered Facebook.
        </p>
        <p>
          Once you register the game, you will be provided with a user id, which
          is used to indentify your account. And profile will be public, but
          will only permit access to information that is considered public or
          that you have designated as public in your settings.
        </p>
        <p>
          When you play our game, we may collect certain technical information
          typically through the use of server log files, mobile device software
          development kits and tracking technology like browser cookies to
          personalize the game experience.
        </p>
        <p>
          Any other information that you enter when using the service may also
          be collected.
        </p>
        <h2>Use of Information</h2>
        <p>
          When you play Teen Patti Champion, we collect some personal
          information in order to render the good service, fulfill your
          requirement, and resolve your issues.
        </p>
        <p>
          We may use it, but not limited to in the following ways: to create
          your account and ensure safe smooth gameplay; to allow user-to-user
          communication; to provide technical support and respond to user
          inquires; to receive feedback to improve our game and customize user
          experience.
        </p>
        <p>
          In addition, we may send push notification to you with your consent to
          inform you the game updates, promotions, and other relevant messages.
          You can choose to opt out of it in the settings of your device.
        </p>
        <p>
          In the event you do not wish that we use your Information for any of
          the purposes specified in the Privacy Policy, you may close your
          account by uninstalling our application.
        </p>
        <p>
          All users of the Service consent to the use of Information for the
          purpose stated in this Privacy Policy.
        </p>
        <h2>Share of Information</h2>
        <p>
          We will not rent, sell or share it with any third party without your
          consent except the following circumstances:
        </p>
        <ul>
          <li>
            In order to perform certain service, we will provide some of your
            information to third party companies on our behalf, including
            payment processing, marketing service and in-game advertising.
          </li>
          <li>
            In Teen Patti Champion, any other players can check your game
            profile while you are playing. They can get access to your game ID,
            basic game data and Facebook name. Moreover, other players may send
            you game requests or even friend requests through Facebook.
          </li>
        </ul>
        <p>
          We will strive to ensure any third party to observe our privacy policy
          and not to use your information for any other purposes.
        </p>
        <h2>Use of Service - Restrictions</h2>
        <p>
          Please be noted your account will be suspended or permanently banned
          if you failed to follow our restriction rules.
        </p>
        <ul>
          <li>
            Teen Patti Champion is not intended for children under the age of
            13. We will take immediate actions to erase their information if
            that happens. You shall not let anyone under 13 to use your account.
          </li>
          <li>
            This game is only for entertainment. You shall not put to other
            uses.
          </li>
          <li>
            You shall not spread any illegal and harmful information in the
            game.
          </li>
          <li>
            You shall not cheat, hack or maliciously exploit the game with your
            account.
          </li>
          <li>You shall not create more than one account to play our game.</li>
          <li>
            You shall not transfer chips to your multiple accounts or your
            friends in private or any other tables on purpose.
          </li>
          <li>
            You are fully responsible for any unauthorized use of the service
            including but not limited to the use of credit card or ant payment
            by any method.
          </li>
          <li>
            You shall not use our service if you disagree with any of our
            policy, including this privacy policy.
          </li>
        </ul>
        <h2>Changes to the Private Policy</h2>
        <p>
          The outline and content of this privacy policy may change after some
          time. We will update this page if any terms are rewritten. You can
          visit our site periodically to ensure you are aware of the recent
          adjustment.
        </p>
        <h2>Contact US</h2>
        <p>
          If you have any problem in regards to this private policy, including
          to practice an of your rights, please contact us through :
          support@jeeto7.com
        </p>
      </div>
      <HideFooter />
    </>
  );
};

export default Privacy;
