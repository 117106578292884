/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { getMemberVipUser } from "api/vip/query";
import { VipMemberDetailType } from "api/vip/type";
import LoadingCustom from "components/common/LoadingCustom";
import HeaderUser from "components/mobile/HeaderUser";
import HideFooter from "components/mobile/HideFooter";
import { QueryKeys } from "constants/queryKeys";
import { queryClient } from "index";
import { useNavigate } from "react-router-dom";
import { ConfigCss } from "styles/config";

import { VipDetailUserStyle } from "./style.index";

const VipDetailUser = () => {
  const username = queryClient.getQueryData<string>([QueryKeys.username]);
  const navigate = useNavigate();

  const { data: dataMemberVip, isLoading } = useQuery<VipMemberDetailType>({
    queryKey: [QueryKeys.MemberVIP],
    queryFn: () => getMemberVipUser(String(username)),
  });
  if (isLoading) {
    return <LoadingCustom height="100vh" />;
  }

  const currentLevel = dataMemberVip?.current_level;
  const nextLevel = dataMemberVip?.next_level.level;

  const currentDeposit = Number(
    dataMemberVip?.next_level.current_deposit?.replace(/\.00$/, ""),
  );
  const targeDeposit = Number(
    dataMemberVip?.next_level.deposit?.replace(/\.00$/, ""),
  );
  const percentDeposit = (currentDeposit / targeDeposit) * 100;

  const currentTurnOver = Number(
    dataMemberVip?.next_level.current_turnover?.replace(/\.00$/, ""),
  );

  const targeTurnOver = Number(
    dataMemberVip?.next_level.turnover?.replace(/\.00$/, ""),
  );

  const percentTurnOver = (currentTurnOver / targeTurnOver) * 100;

  return (
    <>
      <HeaderUser onBack={() => navigate(-1)} title="VIP" />
      <div css={VipDetailUserStyle.wrapper}>
        <div css={[VipDetailUserStyle.header, ConfigCss.flexBetween]}>
          <span>V{currentLevel}</span>
          <span>&gt;</span>
          <span>V{nextLevel}</span>
        </div>

        <div
          css={[
            VipDetailUserStyle.progressDeposit(percentDeposit),
            ConfigCss.flexBetween,
          ]}
        >
          <span>Deposit</span>
          <span>
            {currentDeposit}/{targeDeposit}
          </span>
        </div>

        <div
          css={[
            VipDetailUserStyle.progressRollover(percentTurnOver),
            ConfigCss.flexBetween,
          ]}
        >
          <span>Rollover</span>
          <span>
            {currentTurnOver || 0}/{targeTurnOver || 0}
          </span>
        </div>

        <div css={VipDetailUserStyle.describe}>Claim Bonus Once Promoted</div>

        <div css={VipDetailUserStyle.table}>
          <table>
            <tbody>
              <tr>
                <th>Benefit</th>
                <th
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  Current: V{dataMemberVip?.current_level}
                </th>
                <th
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  Next: V{dataMemberVip?.next_level.level}
                </th>
              </tr>
              <tr>
                <td>Monthly bonus</td>
                <td
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  {dataMemberVip?.board[
                    currentLevel || 0
                  ]?.montly_bonus?.replace(/\.00$/, "")}
                </td>
                <td
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  {dataMemberVip?.board[nextLevel || 0]?.montly_bonus?.replace(
                    /\.00$/,
                    "",
                  )}
                </td>
              </tr>
              <tr>
                <td>Upgrade bonus</td>
                <td
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  {dataMemberVip?.board[
                    currentLevel || 0
                  ]?.level_up_bonus?.replace(/\.00$/, "")}
                </td>
                <td
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  {dataMemberVip?.board[
                    nextLevel || 0
                  ]?.level_up_bonus?.replace(/\.00$/, "")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <HideFooter />
    </>
  );
};

export default VipDetailUser;
