/** @jsxImportSource @emotion/react */

import { message } from "antd";
import { gameApi } from "api/game";
import { ResponseCode } from "constants/response";
import { replacePath } from "helpers/replacePath";
import { ItemProviderHotGame } from "pages/desktop/GamePage/LiveCasino/list-game";
import { MutableRefObject, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GameListProvider, ProviderGameType } from "types/game";

import { GameProviderStyle } from "./index.style";

interface Props {
  currentCode?: string;
  gameType?: string;
  providers?: ProviderGameType[];
  myRef?: MutableRefObject<null>;
}
const initDataGameList: GameListProvider = {
  msg: "",
  status: 0,
  dataGameProvider: [],
};

function GameProvider({
  providers,
  currentCode,
  myRef,
  gameType = "LC",
}: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [dataGameProvider, setDataGameProvider] =
    useState<GameListProvider>(initDataGameList);

  useEffect(() => {
    if (!gameType) return;

    getGameList();
  }, [gameType]);

  const getGameList = async () => {
    const response = await gameApi.getGameList(gameType);
    if (response.data.status !== ResponseCode.SUCCESS) {
      message.destroy();
      message.error(response.data.msg);
      setDataGameProvider(initDataGameList);
      return;
    }

    setDataGameProvider({
      ...response.data,
      dataGameProvider: response.data.data,
    });
  };

  const length = dataGameProvider?.dataGameProvider?.length;

  const handleListProvider = () => {
    // if (pathname.includes("table-games")) {
    //   const listProvider = dataGameProvider?.dataGameProvider?.filter(
    //     (itemFilter) => itemFilter.provider_code !== "T1G",
    //   );
    //   return listProvider;
    // }
    // if (pathname.includes("crash-games")) {
    //   const listProvider = dataGameProvider?.dataGameProvider?.filter(
    //     (itemFilter) => itemFilter.provider_code === "T1G",
    //   );
    //   return listProvider;
    // }
    if (gameType === "SL") {
      const listProvider = dataGameProvider?.dataGameProvider?.filter(
        (itemFilter) =>
          itemFilter.provider_code !== "AVIA" &&
          itemFilter.provider_code !== "SPRI",
      );
      return listProvider;
    }
    return dataGameProvider?.dataGameProvider;
  };

  const listGameProvider = handleListProvider() || [];
  return (
    <div css={GameProviderStyle.index(length || 0)} ref={myRef}>
      <ul css={GameProviderStyle.list}>
        {[ItemProviderHotGame, ...listGameProvider]?.map((item, index) => {
          const isActive = item.provider_code === currentCode;
          return (
            <li
              onClick={() => {
                const newPath = replacePath(pathname, item.provider_code);
                navigate(`${newPath}`, { replace: true });
              }}
              key={index}
              css={GameProviderStyle.item}
            >
              <p className={`link transition-all ${isActive && "selected"}`}>
                {item.provider_name}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default GameProvider;
