import { css, Theme } from "@emotion/react";
import { media } from "styles/breakpoints";

export const MobileNotificationDetailStyle = {
  self: ({ colors }: Theme) => css`
    position: fixed;
    inset: 0;
    background-color: ${colors.white};
    z-index: 9999;
  `,
  header: ({ colors }: Theme) => css`
    padding: 16px 10px;
    background-color: #af1010;
    color: ${colors.white};
    display: flex;
  `,

  replyIcon: css`
    width: 27px;
    align-self: flex-start;
    path {
      fill: white;
    }
  `,
  title: css`
    flex: 1;
    text-align: center;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.5;
    font-weight: 400;
    margin-left: 12px;
    padding-right: 26px;
  `,

  content: css`
    padding: 16px 12px;
    ${media.sm} {
      p {
        font-size: 14px !important;
        line-height: 1.6;
      }
    }
  `,
};
