import { css } from "@emotion/react";

export const ProviderGameStyle = {
  index: css`
    border-bottom: 1px solid #808080;
  `,
  content: css`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  item: (isActive: boolean) => css`
    padding: 13px 19.45px 14.28px 18.55px;
    width: auto;
    white-space: pre;
    color: ${isActive ? "#f44336" : ""};
    border-bottom: ${isActive ? "2px" : "0px"} solid #f44336;
    transition:
      color 0.3s,
      background-color 0.3s;

    &:active {
      background-color: rgb(244, 67, 54, 0.2);
    }
    span {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      line-height: 22.295px;
      letter-spacing: 0.217px;
    }
  `,
};
