import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const LanguageStyle = {
  wrapper: css`
    color: ${theme.colors.white};
    padding: 0.18vw 0.6vw;
    display: flex;
    flex-direction: row;
    align-items: center;

    .flags {
      background-image: url("/assets/app/flags.png");
      width: 52px;
      height: 39px;
      display: inline-block;
      background-repeat: no-repeat;
      margin: 0px -20px;
      background-position: 0 -4593px;
      font-size: 0.6vw;
      transform: scale(0.25);
    }

    .name {
      font-size: 0.8vw;
      margin-left: 4px;
    }
  `,
};
