/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Form, message, Modal } from "antd";
import { UserApi } from "api/user";
import { AvatarUserMobile } from "assets/mobile/images";
import InputFile from "components/common/InputFile";
import MESSAGE from "constants/message";
import { QueryKeys } from "constants/queryKeys";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleCloseModal } from "helpers/handleModal";
import { queryClient } from "index";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ProfileType, UploadAvatarUserPayload } from "types/user";
import * as yup from "yup";

import { ModalUserPictureStyle } from "./style.index";

interface DocumentFormData {
  file: any;
}

const schema = yup.object({
  file: yup.mixed().required("File is required"),
});

const ModalUserPicture = () => {
  const { state, dispatch } = useContext(AppContext);

  const username = queryClient.getQueryData<string>([
    QueryKeys.username,
  ]) as string;
  const { data: dataUserDetail, refetch } = useQuery<ProfileType>([
    QueryKeys.MemberDetail,
  ]);

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload: UploadAvatarUserPayload) =>
      UserApi.uploadAvatarUser(username, payload),
    onSuccess: () => {
      message.success(MESSAGE.UPLOAD_FILE_SUCCESS);
      setPreviewImg("");
      reset({ file: null });
      refetch();
    },
    onError: () => {
      message.error(MESSAGE.UPLOAD_FILE_FAIL);
      setPreviewImg("");
      reset({ file: null });
    },
  });
  const [previewImg, setPreviewImg] = useState<string>("");

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<DocumentFormData>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
      }
      fileReader.onload = () => {
        resolve(fileReader?.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handlePreviewImage = (file: any) => {
    if (previewImg) {
      URL.revokeObjectURL(previewImg);
    }
    setPreviewImg(URL.createObjectURL(file));
  };

  const onSubmit = async (form: DocumentFormData) => {
    const dataBase64 = await convertBase64(form.file);

    if (!dataBase64) return;

    const prefix = "\\";
    const avatar = prefix + (dataBase64 as string).split(",")[1];
    const payload: UploadAvatarUserPayload = {
      username,
      avatar,
    };

    mutate(payload);
    reset();
  };

  return (
    <Modal
      open={state.openModal === ModalTypeValue.USER_PICTURE}
      onCancel={() => handleCloseModal(state, dispatch)}
      css={ModalUserPictureStyle.wrapper}
      footer={null}
      centered
      width={312}
    >
      <Form
        onSubmitCapture={handleSubmit(onSubmit)}
        css={ModalUserPictureStyle.mainContent}
      >
        <div css={ModalUserPictureStyle.title}>My Profile Picture</div>
        <div css={ModalUserPictureStyle.avatar}>
          <img
            src={previewImg || dataUserDetail?.avatar || AvatarUserMobile}
            alt="avatar-user"
          />
        </div>

        <div css={ModalUserPictureStyle.inputFile}>
          <InputFile
            buttonName="Upload your picture"
            icon={false}
            placeholder=""
            control={control}
            file={watch("file")}
            name="file"
            validate_status={errors.file ? "error" : "success"}
            onChange={(file) => handlePreviewImage(file)}
          />
        </div>
        <Button
          loading={isLoading}
          disabled={!isValid}
          htmlType="submit"
          type="primary"
        >
          Done
        </Button>
      </Form>
    </Modal>
  );
};

export default ModalUserPicture;
