import { Theme } from "@emotion/react";

export const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    background: "#050117",
    primary: "#ffbc00",
    red: "#f00",
    gray: {
      150: "#E0E0E0",
    },
    rgb: {
      10: "rgba(255, 255, 255, 0.15)",
      20: "rgba(255, 255, 255, 0.2)",
      30: "rgba(61, 70, 104, 0.3)",
      60: "rgba(61, 70, 104, 0.6)",
    },
    neutrals: {
      50: "#F5F5F9",
      100: "#E5E5E6",
      200: "#CACACE",
      250: "#d1ccd9",
      300: "#AFAFB5",
      400: "#95959D",
      500: "#7B7B84",
      600: "#636369",
      700: "#4A4A4F",
      800: "#313135",
      900: "#1C1C1E",
    },
    quantity: {
      green: "#01A730",
      red: "#D20404",
      blue: "#0477FD",
    },
  },
  fontSize: {
    13: "13px",
    14: "14px",
    15: "15px",
    16: "16px",
    18: "18px",
    20: "20px",
    24: "24px",
    32: "32px",
    36: "36px",
    40: "40px",
    48: "48px",
    64: "64px",
    96: "96px",
  },
} as Theme;
