import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys";
import { AppContextProvider } from "contexts/app.context";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routesConfig from "router";

import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter(routesConfig);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

queryClient.setQueryData([QueryKeys.isAuthenticated], false);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <QueryClientProvider client={queryClient}>
    <AppContextProvider>
      <RouterProvider router={router} />
    </AppContextProvider>
  </QueryClientProvider>,
);

reportWebVitals();
