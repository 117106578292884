/** @jsxImportSource @emotion/react */

import { IconArrowNext } from "assets/mobile/icons/profile";
import {
  LIST_MENU_INFORMATION_USER,
  LIST_SUB_MENU_USER,
} from "constants/menu-user";
import { AppContext } from "contexts/app.context";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ConfigCss } from "styles/config";

import { MenuInfoUserStyle } from "./style.index";

const MenuInformationUser = () => {
  const { state, dispatch } = useContext(AppContext);
  return (
    <div css={MenuInfoUserStyle.wrapper}>
      <div css={MenuInfoUserStyle.listMenu}>
        {LIST_MENU_INFORMATION_USER.map((itemInfo) => (
          <Link
            to={itemInfo.link}
            key={itemInfo.key}
            css={[MenuInfoUserStyle.itemMenu, ConfigCss.flexBetween]}
            onClick={() => {
              if (!dispatch) return;

              if (itemInfo.key === "profile") {
                dispatch({
                  type: "SET_NAVIGATE_PROFILE",
                  payload: {
                    ...state,
                    idNavigateProfile: "user",
                  },
                });
                return;
              }
            }}
          >
            <div css={MenuInfoUserStyle.itemMenuHeader}>
              <img src={itemInfo.icon} alt="" />
              <span>{itemInfo.name}</span>
            </div>
            <div css={MenuInfoUserStyle.btnArrow}>
              <IconArrowNext />
            </div>
          </Link>
        ))}
      </div>
      <div css={MenuInfoUserStyle.listMenu}>
        {LIST_SUB_MENU_USER.map((itemInfo) => (
          <Link
            to={itemInfo.link}
            key={itemInfo.key}
            css={[MenuInfoUserStyle.itemMenu, ConfigCss.flexBetween]}
            onClick={() => {
              if (!dispatch) return;

              if (itemInfo.key === "affiliate") {
                dispatch({
                  type: "SET_NAVIGATE_AFFILIATE",
                  payload: {
                    ...state,
                    idNavigateAffiliate: "user",
                  },
                });
                return;
              }
            }}
          >
            <div css={MenuInfoUserStyle.itemMenuHeader}>
              <img src={itemInfo.icon} alt="" />
              <span>{itemInfo.name}</span>
            </div>
            <div css={MenuInfoUserStyle.btnArrow}>
              <IconArrowNext />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MenuInformationUser;
