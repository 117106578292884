import { css } from "@emotion/react";

export const PrivacyStyle = {
  wrapper: css`
    width: auto;
    padding: 0 10px;
    margin: 0 auto;
    line-height: 1.7;
    color: #170a34;
    font-family: unset !important;
    h1 {
      text-align: center;
    }
    h2 {
      margin-top: 20px;
    }
    p {
      font-size: 16px;
      color: dimgrey;
    }
    ul {
      display: block;
      list-style-type: decimal;

      color: #696969;
      margin-left: 15px;
    }
  `,
};
