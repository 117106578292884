import { css } from "@emotion/react";

export const FooterStyle = {
  index: css`
    background: #050117;
  `,

  info: css`
    max-width: 1700px;
    padding: 0 2vw;
    margin: 0 auto;
    width: 100%;

    .data {
      background-color: #1b1630;
      border-radius: 0.3vw;
      color: #fff;
      display: flex;
      flex-direction: column;
      gap: 0.25vw;
      letter-spacing: 0.8px;
      margin-top: 1.5vw;
      padding: 1.5vw;
      font-size: 0.8vw;

      p {
        color: #fff;
        font-size: 0.8vw;
        font-weight: 400;
        line-height: 1.5vw;
        letter-spacing: 0.01vw;
      }

      .title {
        color: #ffbc00;
        font-size: 1vw;
        font-weight: 700;
        line-height: 2.5rem;
        letter-spacing: 0.00735em;
      }

      h4 {
        letter-spacing: 1vw;
        line-height: 2.5rem;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 0.25vw;
        list-style: disc;
        list-style-position: outside;
        padding: 0.25vw 0 1vw 2vw;
      }
    }
  `,
  content3: css`
    color: #fff;
    display: flex;
    font-size: 0.65vw;
    justify-content: space-between;
    max-width: 1700px;
    padding: 2.5vw 2vw;
    margin: 0 auto;
    width: 100%;
  `,
  allLink: css`
    color: #fff;
    display: flex;
    font-size: 0.65vw;
    justify-content: space-between;

    .links {
      display: flex;
      flex-direction: column;
      letter-spacing: 0.018vw;
      margin-right: 8vw;

      .link {
        color: #fff;
        cursor: pointer;
        margin-bottom: 0.2vw;
        text-decoration: none;
      }

      .title {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  `,
  socials: css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0.75vw;

    .medias {
      display: flex;
      gap: 0.75vw;

      a {
        width: 3vw;

        img {
          width: 100%;
        }
      }
      div {
        width: 3vw;

        img {
          width: 100%;
        }
      }
    }

    .follow-us {
      font-size: 0.8vw;
      text-transform: uppercase;
    }

    .plus-18 {
      align-items: center;
      background: #1c1a1a;
      border-radius: 50%;
      display: flex;
      font-size: 0.8vw;
      justify-content: center;
      width: 2vw;
      height: 2vw;
    }
  `,
};
