/** @jsxImportSource @emotion/react */

import { useMutation } from "@tanstack/react-query";
import { message, Spin } from "antd";
import { BankApi } from "api/bank";
import { ReactComponent as TrashIcon } from "assets/common/icons/trash.svg";
import LoadingCustom from "components/common/LoadingCustom";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { queryClient } from "index";
import { useState } from "react";
import { PlayerBankType } from "types/bank";

import { BankCardStyle } from "./style.index";

interface Props {
  item: PlayerBankType;
  bankAccountNumber: string;
  isFetching: () => void;
}

const BankCard = ({ bankAccountNumber, item, isFetching }: Props) => {
  const username = queryClient.getQueryData<string>([QueryKeys.username]);

  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveBank = async (id: string) => {
    setIsLoading(true);
    const response = await BankApi.removeMemberBank(String(username), {
      username: String(username),
      id,
    });

    if (response.status === ResponseCode.SUCCESS) {
      message.success({
        content: response.msg,
      });
    } else {
      message.error({
        content: response.msg,
      });
    }
    setIsLoading(false);
  };

  const mutation = useMutation((id: string) => handleRemoveBank(id), {
    onSuccess: () => {
      isFetching();
    },
  });

  const handleDelete = (id: string) => {
    mutation.mutate(id);
  };

  return (
    <Spin
      spinning={isLoading}
      indicator={
        <LoadingCustom sizeIcon="20px" height="100%" colorSpin="black" />
      }
    >
      <div key={item.player_bank_id} css={BankCardStyle.wrapper}>
        <div css={BankCardStyle.nameContentBank}>
          <div css={BankCardStyle.nameBank}>
            <img src="/user/bankNameIcon.png" alt="" />
            <span>{item.bank_name}</span>
          </div>
          <div
            css={BankCardStyle.icon}
            onClick={() => handleDelete(item.player_bank_id)}
          >
            <TrashIcon fill="#9e9e9e" />
          </div>
        </div>
        <div css={BankCardStyle.numberBank}>
          <p>{bankAccountNumber}</p>
        </div>
        <div css={BankCardStyle.nameUser}>{item.bank_account_name}</div>
      </div>
    </Spin>
  );
};

export default BankCard;
