"use client";

import dayjs, { Dayjs } from "dayjs";
import { ModalTypeValue } from "enums/modalType";
import { OrderStatus } from "enums/orders";
import { EnumItemMenuUserModal } from "enums/user";
import React, { createContext, Dispatch, useContext } from "react";
import { ContextAction } from "types";

import { reducer } from "./reducer";

export type ContextActionType =
  | "OPENMODAL"
  | "KEYITEM"
  | "KEYMODAL"
  | "START_DATE"
  | "END_DATE"
  | "CURRENT_PAGE"
  | "AMOUNT_WITHDRAWAL"
  | "ANNOUNCEMENT"
  | "MODAL_RECORD"
  | "LAST_LOGGEDIN"
  | "START_DATE_ORDER"
  | "END_DATE_ORDER"
  | "STATUS_ORDER"
  | "TRANFER_TYPE"
  | "SET_NAVIGATE_PROFILE"
  | "SET_NAVIGATE_AFFILIATE"
  | "START_DATE_BETTING"
  | "END_DATE_BETTING";

export type TransferTypeValue = "cashback" | "reward" | "bonus" | "all";
export interface AppState {
  isAuthenticated: boolean;
  username: string;
  openModal: ModalTypeValue;
  openModalRecord: ModalTypeValue;
  keyItem: EnumItemMenuUserModal;
  keymodal: EnumItemMenuUserModal;
  startDate: Dayjs;
  transfer_type: TransferTypeValue;
  endDate: Dayjs;
  startDateBetting: Dayjs;
  endDateBetting: Dayjs;
  currentPage: number;
  amountWithDrawal: string;
  lastLoggedIn: string;
  startDateOrder: string;
  endDateOrder: string;
  statusOrder: OrderStatus;
  idNavigateProfile: string;
  idNavigateAffiliate: string;
}

interface IAppContext {
  state: AppState;
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null;
}

const initialState: AppState = {
  isAuthenticated: false,
  username: "",
  openModal: ModalTypeValue.CLOSE,
  openModalRecord: ModalTypeValue.CLOSE,
  keyItem: EnumItemMenuUserModal.profileData,
  keymodal: EnumItemMenuUserModal.profile,
  startDate: dayjs(),
  endDate: dayjs(),
  currentPage: 1,
  amountWithDrawal: "",
  lastLoggedIn: "",
  startDateOrder: dayjs().format("YYYY-MM-DD"),
  endDateOrder: dayjs().format("YYYY-MM-DD"),
  statusOrder: OrderStatus.Void,
  idNavigateProfile: "",
  idNavigateAffiliate: "",
  startDateBetting: dayjs(),
  endDateBetting: dayjs(),
  transfer_type: "all",
};

export const AppContext = createContext<IAppContext>({
  state: initialState,
  dispatch: null,
});

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext<IAppContext>(AppContext);
