import loadable from "@loadable/component";
import App from "App";
import { PATH } from "constants/path";
import { DefaultLayout } from "layouts/desktop/DefaultLayout";
import LoginAffiliatePage from "pages/common/LoginAffiliatePage";
import CrashPage from "pages/desktop/GamePage/CrashGame";
import RegisterAffiliatePage from "pages/desktop/RegisterAffiliatePage";
import { RouteObject } from "react-router-dom";

const HomePage = loadable(() => import("pages/desktop/HomePage"));
const Promotions = loadable(() => import("pages/desktop/Promotions"));
// game page
const SportsPage = loadable(() => import("pages/desktop/GamePage/Sports"));
const FlightPage = loadable(() => import("pages/desktop/GamePage/Flight"));
const LiveCasino = loadable(() => import("pages/desktop/GamePage/LiveCasino"));
const SlotPage = loadable(() => import("pages/desktop/GamePage/Slot"));
const TableGame = loadable(() => import("pages/desktop/GamePage/TableGame"));
const Share = loadable(() => import("pages/desktop/Share"));

export const DesktopRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: PATH.Home,
            element: <HomePage />,
          },
          {
            path: PATH.Promotions,
            element: <Promotions />,
          },
          //game
          {
            path: PATH.GamePage.Sports,
            element: <SportsPage />,
          },
          {
            path: PATH.GamePage.Flight,
            element: <FlightPage />,
          },
          {
            path: PATH.GamePage.LiveCasino,
            element: <LiveCasino />,
          },
          {
            path: `${PATH.GamePage.LiveCasino}/:provider_code`,
            element: <LiveCasino />,
          },
          {
            path: PATH.GamePage.Slot,
            element: <SlotPage />,
          },
          {
            path: `${PATH.GamePage.Slot}/:provider_code`,
            element: <SlotPage />,
          },
          {
            path: PATH.GamePage.TableGames,
            element: <TableGame />,
          },
          {
            path: PATH.GamePage.CrashGames,
            element: <CrashPage />,
          },
          {
            path: `${PATH.GamePage.CrashGames}/:provider_code`,
            element: <CrashPage />,
          },
          {
            path: `${PATH.GamePage.TableGames}/:provider_code`,
            element: <TableGame />,
          },
          {
            path: PATH.share,
            element: <Share />,
          },
        ],
      },
      {
        path: PATH.agentLogin,
        element: <LoginAffiliatePage />,
      },
      {
        path: PATH.agentRegister,
        element: <RegisterAffiliatePage />,
      },
    ],
  },
];
