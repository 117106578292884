import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys";
import { WithDrawalFormSettingType } from "types/withdraw";

import { getWithdrawalForm } from "./queryFnsWithdraw";

export const initialDataWithdraw: WithDrawalFormSettingType = {
  player_bank: [],
  currencies: [],
  setting: {
    min_withdrawal: "",
    max_withdrawal: "",
  },
  withdraw_limit: 0,
  pending_rollover: {
    amount: "",
    detail: [],
  },
};

export const useQueryWithdraw = (username: string) =>
  useQuery<WithDrawalFormSettingType>({
    queryKey: [QueryKeys.WithDrawForm, username],
    queryFn: () => getWithdrawalForm(String(username)),
  });
