import { css } from "@emotion/react";

export const JackPotStyle = {
  jackpot: {
    index: css`
      text-align: center;
      color: #d4d4d4;
      font-weight: 700;
      overflow: hidden;
      padding: 3vw 0;
    `,
    title: css`
      font-size: 1vw;
      letter-spacing: 0.3vw;
      overflow: hidden;
      text-transform: uppercase;
      &::before {
        margin-left: -50%;
        right: 0.5em;
      }
      &::after {
        left: 0.5em;
        margin-right: -50%;
      }
      &::before,
      &::after {
        background-color: #fff;
        content: "";
        display: inline-block;
        height: 0.05vw;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }
    `,
    amount: css`
      font-size: 5vw;
      line-height: 5vw;
      margin-top: 1vw;
      overflow: hidden;
      position: relative;
    `,
  },
};
