export const QueryKeys = {
  // app
  isAuthenticated: "isAuthenticated",
  username: "username",
  // api
  MemberLogin: "MemberLogin",
  GetGameList: "GetGameList",
  MemberRegister: "MemberRegister",
  GetMemberDoc: "GetMemberDoc",
  // user
  MemberDetail: "MemberDetail",
  MemberVIP: "MemberVIP",
  ContactUs: "ContactUs",
  MemberBalance: "MemberBalance",
  Notification: "Notification",
  MemberBank: "MemberBank",
  WithDrawForm: "WithDrawForm",
  GetBankList: "GetBankList",
  DepositForm: "DepositForm",
  DepositMemberOrder: "DepositMemberOrder",
  RebaseMemberOrder: "RebaseMemberOrder",
  WithDrawalMemberOrder: "WithDrawalMemberOrder",
  MemberDepositOrders: "MemberDepositOrders",
  Referral: "Referral",
  token: "token",
  PromotionList: "PromotionList",
  MemberPromotion: "MemberPromotion",
  CashBackReward: "CashBackReward",
  BettingRecord: "BettingRecord",
  ReferralOrder: "ReferralOrder",
  TradeNo: "TradeNo",
  Providers: "Providers",
};
