import { css } from "@emotion/react";
import { ImageBgHeaderProfile } from "assets/mobile/images/profile";
import { theme } from "styles/theme";

export const InformationUserStyle = {
  wrapper: css`
    padding-bottom: 70px;
  `,

  header: {
    index: css`
      background-image: url(${ImageBgHeaderProfile});
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: top left;

      padding-top: 31.19px;
    `,

    informationGroup: css`
      display: flex;
      align-items: flex-start;
      justify-content: center;
    `,

    btnGroup: css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 16px;
    `,
  },

  funcUser: css`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 23.63px;

    text-decoration: none;

    span {
      color: ${theme.colors.black};
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 9px;

      margin-top: 4px;
    }
    img {
      width: 33px;
      height: 33px;
    }
  `,

  funcUserItem: css`
    display: flex;
    flex-direction: column;
  `,

  infoUser: {
    index: css`
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0 35px;
      p {
        color: ${theme.colors.black};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20.256px;
        &:nth-of-type(2) {
          font-size: 12px;
          font-size: 1.2vh;
          line-height: 1;
          font-weight: 400;
          letter-spacing: 0.03333em;

          margin-bottom: 10px;
        }
      }
    `,

    avatar: css`
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      position: relative;
      img {
        height: 67px;
        width: 67px;

        border-radius: 50%;
      }
      span {
        position: absolute;
        bottom: -10px;
        right: 50%;
        width: 65%;
        font-size: 10px;
        color: ${theme.colors.white};
        text-transform: uppercase;
        border-radius: 4px;
        text-align: center;

        padding: 2px 6px;
        background-color: #ff1a00;
        transform: translateX(50%);
      }
    `,

    balance: css`
      display: flex;
      align-items: center;
      gap: 3.02px;
      span {
        color: #1d1d1d;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.1px;
      }
    `,
  },

  btn: {
    index: css`
      box-shadow: 0px 5px 15px 0px rgba(122, 121, 121, 0.35);
      background-color: ${theme.colors.white};
      width: 93px;
      border-radius: 10px;
      padding: 6px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;

      cursor: pointer;

      text-decoration: none;

      img {
        width: 21.31px;
      }

      color: ${theme.colors.black};
      font-size: 9.972px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.458px;

      &:first-of-type {
        background-color: #ff1900;
        color: ${theme.colors.white};
      }
      &:nth-of-type(2) {
        background-color: #00d8a0;
        color: ${theme.colors.white};
      }
    `,
  },
};
