import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const MenuInfoUserStyle = {
  wrapper: css`
    padding: 16px;
    max-height: 55vh;
    overflow: auto;
    padding-bottom: 20%;

    display: flex;
    flex-direction: column;

    gap: 16px;
  `,

  listMenu: css`
    a {
      text-decoration: none;
    }
    border-radius: 5px;
    background-color: rgba(187, 185, 185, 0.2);
    display: flex;
    flex-direction: column;
    gap: 1px;

    &:first-of-type {
      box-shadow: 0 7px 29px 0 #64646f33;
    }
    &:last-of-type {
      box-shadow: 0 7px 29px 0 #64646f33;
    }
  `,

  itemMenu: css`
    padding: 12px 27.96px 12px 10.52px;
    border-radius: 5px;
    background: ${theme.colors.white};
  `,

  itemMenuHeader: css`
    display: flex;
    align-items: center;
    img {
      height: 13.64px;
      width: 13.64px;
    }

    span {
      margin-left: 43.36px;

      color: ${theme.colors.black};
      font-size: 12.504px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.256px;
    }
  `,

  btnArrow: css``,
};
