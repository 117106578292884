/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import InputCustom from "components/common/InputCustom";
import InputPassword from "components/common/InputPassword";
import { PATH } from "constants/path";
import { useNavigate } from "react-router-dom";
import { ConfigCss } from "styles/config";

import { RegisterAffiliateMStyle } from "./style.index";

const RegisterAffiliate = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH.agentLogin);
  };

  return (
    <div css={RegisterAffiliateMStyle.wrapper}>
      <div css={RegisterAffiliateMStyle.header}>
        <h2>Join</h2>
        <p>
          Most authoritative online events group.
          <br />
          You call it, we do it.
        </p>
        <span>Register Now!</span>
      </div>
      <div css={RegisterAffiliateMStyle.mainContent}>
        <h1>AFFILIATE</h1>
        <p>Registration</p>
        <div css={RegisterAffiliateMStyle.form}>
          <label htmlFor="">
            Full Name <span> *</span>
          </label>
          <InputCustom />

          <label htmlFor="">
            Email <span> *</span>
          </label>
          <InputCustom />

          <label htmlFor="">
            Username <span> *</span>
          </label>
          <InputCustom />

          <label htmlFor="">
            Password <span> *</span>
          </label>
          <InputPassword />

          <label htmlFor="">Whatsapp</label>
          <InputCustom />

          <label htmlFor="">Telegram</label>
          <InputCustom />

          <label htmlFor="">Skype</label>
          <InputCustom />

          <div css={[RegisterAffiliateMStyle.btnGroup, ConfigCss.flexCenter]}>
            <Button type="primary">Register</Button>
            <Button onClick={() => handleNavigate()}>Back</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterAffiliate;
