import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const DefaultStyle = {
  index: css``,
  header: css`
    position: sticky;
    top: 0;
    z-index: 99;
  `,
  mainContent: css`
    background-color: ${theme.colors.background};
  `,
};
