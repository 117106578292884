import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ModalUserDetailStyle = {
  wrapper: css`
    .ant-modal-content {
      padding: unset !important;
    }
    .ant-modal-close {
      top: 4px;
      right: 4px;
    }

    label {
      color: #1d1d1d;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 19.5px;
      letter-spacing: -0.5px;
    }
  `,

  mainContent: css``,

  title: css`
    color: ${theme.colors.black};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.5px;

    padding-top: 46px;
  `,

  form: css`
    padding: 16px;

    .ant-picker {
      height: 40px;
    }
    .ant-form-item {
      margin-bottom: unset !important;
    }
  `,

  label: css`
    &:not(:first-of-type) {
      padding-top: 16px;
    }
    span {
      color: #616161;
      font-size: 9px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: -0.5px;
    }
  `,

  tip: css`
    margin: 8px 0;

    color: #ff1900;
    text-align: center;
    font-size: 8.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 14.25px;
    letter-spacing: 0.5px;
  `,

  btnSubmit: css`
    text-align: center;

    padding: 0 16px;

    button {
      background-color: #ff1900 !important;
      width: 100%;
      &:disabled {
        color: white !important;
        opacity: 0.5;
      }
    }
  `,
};
