import { PATH } from "constants/path";

export interface MenuItemType {
  name: string;
  link: string;
  provider_code?: string;
  game_type?: string;
  is_included?: boolean;
}
export const ListMenus: MenuItemType[] = [
  { name: "HOME", link: "/" },
  {
    name: "SPORTS",
    link: PATH.GamePage.Sports,
    provider_code: "",
    game_type: "SB",
  },
  {
    name: "Flight",
    link: PATH.GamePage.Flight,
    provider_code: "",
    game_type: "OT",
  },
  {
    name: "Live Casino",
    link: PATH.GamePage.LiveCasino,
    provider_code: "",
    game_type: "LC",
    is_included: true,
  },
  {
    name: "Casino",
    link: PATH.GamePage.Slot,
    provider_code: "",
    game_type: "SL",
    is_included: true,
  },
  {
    name: "Table Games",
    link: PATH.GamePage.TableGames,
    provider_code: "",
    game_type: "BG",
    is_included: true,
  },
  // {
  //   name: "Crash Games",
  //   link: PATH.GamePage.CrashGames,
  //   provider_code: "",
  //   game_type: "BG",
  //   is_included: true,
  // },
  {
    name: "Promotions",
    link: PATH.Promotions,
    provider_code: "",
    game_type: "",
  },
  {
    name: "News",
    link: "##",
    provider_code: "",
    game_type: "",
  },
];
