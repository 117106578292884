import { css } from "@emotion/react";

export const BankListUserStyle = {
  wrapper: css`
    padding: 58px 12px 0 12px;
  `,

  btn: css`
    width: 100%;
    height: 40px;
    margin-top: 12px;
    font-size: 13px;
    border-radius: 8px;
    border: 1px solid #d0d0d0;
  `,
};
