import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const HeaderAuthenticationStyle = {
  wrapper: css`
    display: flex;
    align-items: center;

    gap: 5px;
  `,

  balance: css`
    color: ${theme.colors.white};
    padding-top: 4px;
  `,

  avatar: css`
    background: #050117;
    border: 0.15vw solid #0a203b;
    border-radius: 50%;
    cursor: pointer;
    height: 29.56px;
    width: 29.56px;

    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  `,

  dropdownItem: css`
    color: ${theme.colors.white};
    font-size: 11.48px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.72px;
    min-width: 100%;

    display: flex;
    align-items: center;
    gap: 11.51px;

    padding: 6px 46px 6px 5px;

    img {
      width: 13.05px;
      height: 13.05px;
    }
  `,

  message: css`
    height: 24.23px;
    width: 24.23px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
};
