/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { Button, Form, message } from "antd";
import { AuthApi } from "api/auth";
import { IconPrev } from "assets/desktop/homepage/icons";
import { ReactComponent as DropdownIcon } from "assets/mobile/icons/icon_drop-down.svg";
import HelmetCustom from "components/common/Helmet";
import InputCustomMobile from "components/mobile/base/InputCustomMobile";
import HideFooter from "components/mobile/HideFooter";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ApiResponse } from "types/api";
import * as yup from "yup";

import { ForgotPasswordStyle } from "./index.style";

export interface ForgotPasswordForm {
  username?: string;
  email: string;
  mobile: string;
}

const schema = yup.object({
  // username: yup.string().trim().required("This is field"),
  email: yup.string().trim().email().required("This is field required"),
  mobile: yup.string().trim().required("This is field required"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();

  const isAuthenticated = useQuery([QueryKeys.isAuthenticated]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messagePassword, setMessagePassword] = useState<string>("");
  const [valueMobile, setValueMobile] = useState<string>("");

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm<ForgotPasswordForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    if (!isAuthenticated.data) return;
    navigate("/");
  }, []);

  const onSubmit = async (data: ForgotPasswordForm) => {
    const username = "91" + valueMobile;
    setIsLoading(true);
    const response = await AuthApi.forgotPassword(username, {
      ...data,
      mobile: "91" + valueMobile,
      username,
    });

    const { msg, status } = response.data as ApiResponse;
    setIsLoading(false);

    if (status !== ResponseCode.SUCCESS) {
      message.error({
        content: msg,
      });
      return;
    }
    message.success({
      content: "Password reset successfully",
    });
    setMessagePassword(msg);
    setValueMobile("");
    reset();
  };

  return (
    <div css={ForgotPasswordStyle.header}>
      <HelmetCustom title="SignIn" />
      <div
        onClick={() => {
          setMessagePassword("");
          navigate(PATH.Home);
        }}
        css={ForgotPasswordStyle.icon}
      >
        <IconPrev />
      </div>
      <div
        css={css`
          margin-bottom: 27px;
          width: 100%;
          text-align: center;
          font-weight: 500;
          font-size: 20px;
        `}
      >
        Forgot Password
      </div>

      <Form onSubmitCapture={handleSubmit(onSubmit)}>
        {messagePassword && (
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            {messagePassword}
          </div>
        )}

        <div>
          <label
            css={css`
              font-weight: 700;
              font-size: 13px;
            `}
          >
            Mobile
          </label>

          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 4px;
              margin-bottom: 40px;
            `}
          >
            <div
              css={css`
                flex: 3;
                display: flex;
                align-items: center;
                padding: 6px 12px;
                justify-content: space-between;
                height: 40px;
                border: 1px solid#c9c9c9;
                border-radius: 4px;
              `}
            >
              <p
                css={css`
                  padding-top: 2px;
                `}
              >
                +91
              </p>
              <div>
                <DropdownIcon />
              </div>
            </div>
            <div
              css={css`
                flex: 10;
              `}
            >
              <InputCustomMobile
                control={control}
                name={"mobile"}
                value={valueMobile}
                margin_bottom="0"
                setValueProps={(value: string) => {
                  if (value.length > 10) return;
                  const regex = new RegExp(/^[0-9]+$/);

                  if (!regex.test(value)) {
                    if (value.length === 0 && value === "") setValueMobile("");
                    return;
                  }

                  setValueMobile(value);
                }}
              />
            </div>
          </div>
        </div>

        <div
          css={css`
            margin-bottom: 40px;
          `}
        >
          <label
            css={css`
              font-weight: 700;
              font-size: 13px;
            `}
          >
            Email
          </label>
          <InputCustomMobile
            control={control}
            name={"email"}
            margin_bottom="0"
          />
        </div>

        <div
          css={css`
            margin: 72px 0 112px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}
        >
          <Button
            css={css`
              width: 100%;
              padding: 10px;
              background-color: ${!isValid || !valueMobile
                ? "#9e9e9e !important"
                : "#00d8a0"};
              border-color: transparent !important;

              height: 100%;
              opacity: ${!isValid || !valueMobile ? 0.7 : 1};
            `}
            disabled={!isValid || !valueMobile}
            loading={isLoading}
            htmlType="submit"
          >
            <span
              css={css`
                height: 100%;
                font-size: 13px;
                font-weight: 500;
                color: ${!isValid || !valueMobile
                  ? "#424242!important"
                  : "white"};
              `}
            >
              Submit
            </span>
          </Button>
        </div>
      </Form>
      <HideFooter />
    </div>
  );
};

export default ForgotPassword;
