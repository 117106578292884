export const userPath = "/me";
export const userSettingPath = "/setting";
export const userProfile = "/profile";

export const PATH = {
  Home: "/",
  Signin: "/login",
  agentLogin: "/agent-login",
  agentRegister: "/agent-register",
  Signup: "/register",
  Promotions: "/promotions",
  Support: "/support",
  ChangePassword: "/change-password",
  Settings: "/settings",
  ForgotPassword: "/forgot-password",
  about: "/about",
  affiliate: "/affiliate",
  share: "/share",
  privacy: "/privacy",
  GamePage: {
    LoginGame: "/open-game",
    Sports: "/sports",
    Flight: "/flight",
    LiveCasino: "/live-casino",
    Slot: "/slot",
    TableGames: "/table-games",
    CrashGames: "/crash-games",
  },
  user: {
    index: userPath,
    affiliate: userPath + "/affiliate",
    profile: userPath + userProfile,
    mobileUser: userPath + userSettingPath + "/mobile",
    panCard: userPath + userSettingPath + "/pancard",
    bankListUser: userPath + userProfile + "/bankList",
    bankEnrollUser: userPath + userProfile + "/enroll",
    vipLevel: userPath + userProfile + "/vip-level",
    notification: userPath + userProfile + "/notification",
    deposit: userPath + userProfile + "/deposit",
    withdraw: userPath + userProfile + "/withdraw",
    transaction: userPath + userProfile + "/transaction",
    bettingRecord: userPath + userProfile + "/betting",
    referral: userPath + userProfile + "/referral",
    wallet: userPath + userProfile + "/wallet",
    reward: userPath + userProfile + "/rewards",
    affiliateProgram: userPath + "/referral-program",
    affiliateReward: userPath + "/referral-reward",
    affiliateDirectQuery: userPath + "/direct-query",
    affiliatePerformance: userPath + "/referral-bonus",
    cashback: userPath + "/cashback",
  },
};
