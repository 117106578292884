/** @jsxImportSource @emotion/react */

import { MemberDetailType } from "api/auth/type";
import HeaderUser from "components/mobile/HeaderUser";
import HideFooter from "components/mobile/HideFooter";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { obscurePhoneNumber } from "helpers/obscuredPhoneNumber";
import { queryClient } from "index";
import { Link, useNavigate } from "react-router-dom";

import { EditPhoneUserStyle } from "./style.index";

const EditPhoneUser = () => {
  const navigate = useNavigate();

  const dataProfileUser = queryClient.getQueryData<MemberDetailType>([
    QueryKeys.MemberDetail,
  ]);
  return (
    <>
      <HeaderUser onBack={() => navigate(-1)} title="Mobile Number" />
      <div css={EditPhoneUserStyle.wrapper}>
        <div css={EditPhoneUserStyle.numberPhone}>
          <h3>My mobile number</h3>
          <span>{obscurePhoneNumber(dataProfileUser?.mobile as string)}</span>
        </div>

        <div css={EditPhoneUserStyle.contact}>
          <h3>How to change my number?</h3>
          <p>Contact our customer service to change your mobile number</p>

          <Link to={PATH.Support} type="text">
            Contact Now
          </Link>
        </div>
      </div>

      <HideFooter />
    </>
  );
};

export default EditPhoneUser;
