/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Image, message } from "antd";
import { MemberDetailType } from "api/auth/type";
import { dailyCashBackBonusClaim, getMemberCashBackReward } from "api/cash";
import CashbackMobile from "assets/common/images/Cashback-mobile.png";
import HeaderUser from "components/mobile/HeaderUser";
import { QueryKeys } from "constants/queryKeys";
import { queryClient } from "index";
import { useEffect } from "react";

export const CashBack = () => {
  const username = queryClient.getQueryData<string>([QueryKeys.username]) || "";

  const { data: cashback, isLoading } = useQuery({
    queryKey: [QueryKeys.CashBackReward],
    queryFn: () => getMemberCashBackReward({ username }),
  });
  const { data: profile } = useQuery<MemberDetailType>([
    QueryKeys.MemberDetail,
  ]);
  const {
    data: claimData,
    mutate,
    isLoading: loadingClaim,
  } = useMutation({
    mutationKey: ["DailyCashBackBonusClaim"],
    mutationFn: dailyCashBackBonusClaim,
    onError: (err) => {
      message.error(err + "");
    },
    onSuccess: (data) => {
      if (data.msg) {
        message.success(data.msg + ". " + data.numberofsuccessful);
      }
    },
  });

  useEffect(() => {
    if (!profile) return;
    mutate({
      player_id: profile.player_id,
      claim: false,
      username: username as string,
    });
  }, [profile]);

  const showCashbackReward = () => {
    if (isLoading) return "Cashback 0.00";

    if (cashback?.data.length === 0) return "Cashback 0.00";

    if (cashback?.data[0].reward) return `Cashback ${cashback?.data[0].reward}`;
  };

  const handleClaim = () => {
    mutate({
      player_id: profile?.player_id as string,
      claim: true,
      username: username as string,
    });
  };
  return (
    <>
      <HeaderUser title="Cashback" />
      <div
        css={css`
          padding: 63px 15px 15px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            padding-left: 10px;
          `}
        >
          <div
            css={css`
              width: 33%;
            `}
          >
            <Image
              src={CashbackMobile}
              alt=""
              height={52}
              width={46}
              css={css`
                width: 100%;
                height: auto;
              `}
              preview={false}
            />
          </div>

          <p
            css={css`
              font-size: 13px;
              display: flex;
              flex-direction: column;
            `}
          >
            {showCashbackReward()}
            {claimData?.showClaimButton && (
              <Button
                onClick={handleClaim}
                css={css`
                  margin-left: 0.4vw;
                  font-weight: bold;
                  cursor: pointer;
                  span {
                    text-decoration: underline;
                  }
                `}
                loading={loadingClaim}
                type="text"
              >
                <span>Claim</span>
              </Button>
            )}
          </p>
        </div>
      </div>
    </>
  );
};
