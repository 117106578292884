import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const PanCardMobileStyle = {
  wrapper: css`
    padding: 48px 40px 0 40px;
  `,

  form: css``,

  formGroup: css`
    label {
      display: block;
      font-size: 3.5vw;
      margin-bottom: 5px;
      width: 100%;
    }

    .ant-input-affix-wrapper,
    .ant-picker {
      border: 1.5px solid #00d39c !important;
      overflow: hidden;
    }
  `,

  inputFile: css`
    align-items: center;
    background-color: #d1d1d1;
    display: flex;
    height: 200px;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      rect {
        fill: transparent;
      }
      path {
        fill: black;
      }
    }
  `,

  btnSubmit: css`
    button {
      width: 100%;
      height: 42px;
    }
  `,

  title: css`
    color: grey;
    font-size: 4.2vw;
    font-weight: 700;
    margin-top: 20px;
  `,

  iconCalendar: () => css`
    color: ${theme.colors.white};
  `,

  loading: css`
    padding-bottom: 52px;
  `,
};
