import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ForgotPasswordStyle = {
  header: css`
    width: 100%;
    height: 100%;
    padding: 24px;

    position: relative;
  `,

  icon: css`
    position: absolute;
    path {
      fill: ${theme.colors.black};
    }
  `,
};
