/** @jsxImportSource @emotion/react */

import { useState } from "react";

import { FooterStyle } from "./index.style";
import { infoHtml } from "./info";
import { listLinks } from "./list-link";
import ModalDetail from "./ModalDetail";
import QRDownload from "./QRDownload";

export interface SelectType {
  key: string;
  index: number;
}
export const Footer = () => {
  const [infoLink, setInfoLink] = useState<SelectType>();
  return (
    <div css={FooterStyle.index}>
      <QRDownload />
      <div css={FooterStyle.info}>
        <div className="data">
          <div dangerouslySetInnerHTML={{ __html: infoHtml }} />
        </div>
      </div>
      <div css={FooterStyle.content3}>
        <div css={FooterStyle.allLink}>
          <div className="links">
            <p
              onClick={() => {
                setInfoLink({
                  key: "about",
                  index: 0,
                });
              }}
              className="link title"
            >
              About
            </p>
            {listLinks.about.map((item, index) => {
              return (
                <p
                  onClick={() => {
                    setInfoLink({
                      key: "about",
                      index: index,
                    });
                  }}
                  key={index}
                  className="link"
                >
                  {item.name}
                </p>
              );
            })}
          </div>
          <div className="links">
            <p
              onClick={() => {
                setInfoLink({
                  key: "help",
                  index: 0,
                });
              }}
              className="link title"
            >
              HELP
            </p>
            {listLinks.help.map((item, index) => {
              return (
                <p
                  onClick={() => {
                    setInfoLink({
                      key: "help",
                      index: index,
                    });
                  }}
                  key={index}
                  className="link"
                >
                  {item.name}
                </p>
              );
            })}
          </div>
          <div className="links">
            <p
              onClick={() => {
                setInfoLink({
                  key: "regulations",
                  index: 0,
                });
              }}
              className="link title"
            >
              REGULATIONS
            </p>
            {listLinks.regulations.map((item, index) => {
              return (
                <p
                  onClick={() => {
                    setInfoLink({
                      key: "regulations",
                      index: index,
                    });
                  }}
                  key={index}
                  className="link"
                >
                  {item.name}
                </p>
              );
            })}
          </div>
        </div>
        <div css={FooterStyle.socials}>
          <div className="medias">
            {/* <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100063519562490&paipv=0&eav=AfZ5kMeQyoPjrwDP9Dzpm2AuqXeVwVs4jwviKtn0T2wyFPQXiC-bl4uyzhXqWjzKToU"
              rel="noreferrer"
            > */}
            <div>
              <img src="/assets/footer/facebook.png" alt="" />
            </div>
            {/* </a> */}
            {/* <a
              target="_blank"
              href="https://www.instagram.com/becric.india/"
              rel="noreferrer"
            > */}
            <div>
              <img src="/assets/footer/instagram.png" alt="" />
            </div>
            {/* </a> */}
          </div>
          <p className="follow-us">Follow us</p>
          <p className="plus-18">18+</p>
        </div>
      </div>
      <ModalDetail itemLink={infoLink} onClose={() => setInfoLink(undefined)} />
    </div>
  );
};
