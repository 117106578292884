import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const RolloverStyle = {
  index: css`
    width: 60%;
    margin: 0 auto;
    ${media.md} {
      width: 100%;
      margin-top: 8px;
    }
  `,
  progressTurnOver: (percent?: number) => css`
    position: relative;
    border-radius: 28.8px;
    background: #5c49d8;
    overflow: hidden;
    padding: 0 9.6px;
    text-align: center;

    &::before {
      content: "";
      width: ${percent}%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #9e7ede;
      border-radius: 28.8px;
    }
    span {
      width: 100%;
      text-align: center;
      z-index: 9;
      color: ${theme.colors.white};
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 23.04px;
      &:first-of-type {
        font-size: 12px;
        font-weight: 700;
        ${media.md} {
          font-size: 10px;
        }
      }
    }
  `,
};
