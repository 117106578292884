/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import LoadingCustom from "components/common/LoadingCustom";
import HeaderUser from "components/mobile/HeaderUser";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { queryClient } from "index";
import { useQueryWithdraw } from "query/withdraw/queryHookWithdraw";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { PlayerBankType } from "types/bank";

import BankCard from "./BankCard";
import { BankListUserStyle } from "./style.index";

const BankListUser = () => {
  const username = queryClient.getQueryData<string>([QueryKeys.username]);

  const navigate = useNavigate();

  const {
    data: dataWithdraw,
    isFetching,
    refetch,
  } = useQueryWithdraw(String(username));

  const renderLayout = () => {
    if (isFetching) {
      return <LoadingCustom height="100vh" />;
    }

    return (
      <Fragment>
        {dataWithdraw?.player_bank.map((item: PlayerBankType) => {
          const sizeAccountNo = item.bank_account_no.length / 2;
          const bankAccountNumber =
            item.bank_account_no?.substring(0, sizeAccountNo - 1) +
            "**" +
            item.bank_account_no?.substring(sizeAccountNo + 1);

          return (
            <BankCard
              isFetching={refetch}
              bankAccountNumber={bankAccountNumber}
              item={item}
            />
          );
        })}
        {(!dataWithdraw?.player_bank.length ||
          dataWithdraw?.player_bank.length < 3) && (
          <Button
            css={BankListUserStyle.btn}
            onClick={() => {
              navigate(PATH.user.bankEnrollUser);
            }}
          >
            Enroll Bank
          </Button>
        )}
      </Fragment>
    );
  };

  return (
    <>
      <HeaderUser title="Enroll" onBack={() => navigate(PATH.user.profile)} />
      <div css={BankListUserStyle.wrapper}>{renderLayout()}</div>
    </>
  );
};

export default BankListUser;
