import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const RewardsStyle = {
  wrapper: css`
    padding: 48px 16px 16px;
    margin-top: 9.5px;
  `,

  header: {
    index: css`
      margin-bottom: 8px;
    `,

    title: css`
      display: flex;
      align-items: center;
      justify-content: flex-start;

      gap: 7.72px;
      p {
        color: #000;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
      }
      span {
        color: #ff1900;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
      }
    `,

    describe: css`
      color: #9e9e9e;
      font-size: 8px;
      font-style: normal;
      font-weight: 500;
      line-height: 13.5px;
    `,
  },

  formGroup: css`
    gap: 8px;

    margin-top: 12px;
    padding: 0 18.88px;
    text-align: right;
    input {
      &::placeholder {
        color: #9e9e9e;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.141px;
      }
    }
    button {
      height: 40px;

      border-radius: 5px;
      background: #00d8a0;
      span {
        padding: 0 11px;

        color: ${theme.colors.white};
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 181.818% */
        letter-spacing: 0.4px;
      }
    }
  `,

  listRewards: css`
    margin-top: 17.5px;

    display: flex;
    flex-direction: column;

    gap: 10px;
  `,

  itemReward: css`
    position: relative;
    img {
      width: 100%;
      object-fit: contain;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px 15px 8px -9px #82a3cf;

      border-radius: 20px;
    }
    button {
      width: 100%;
      border-radius: 5px;
      background: ${theme.colors.primary} !important;
      &:disabled {
        opacity: 0.7;
      }
      span {
        color: white;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 166.667% */
        letter-spacing: 0.25px;
      }
    }
  `,

  rewardContent: css`
    position: absolute;
    width: 50%;
    color: #fff;
    height: 100%;
    right: 0;
    top: 0;
    padding: 0 10px 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  `,

  textStatus: css`
    background: linear-gradient(58deg, #cb55c0, #131e9b);
    box-shadow: -1px 1px 5px 3px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: -1px 1px 5px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -1px 1px 5px 3px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    p {
      padding: 4px 10px;
      text-align: center;
      font-size: 14px;
      color: white;
    }
  `,

  TC: css`
    margin-left: 10px;
    font-size: 14px;
    ol {
      margin-top: 10px;
      list-style-position: inside;
    }
  `,
};
