import { css } from "@emotion/react";

export const CrashStyle = {
  index: css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
  `,
  header: css`
    background-color: #fff;
  `,
  content: css`
    position: relative;
    flex: 1;
    overflow-y: auto;
  `,
  loadMore: css`
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      background: #ffbc00;
      font-size: 12px;
      letter-spacing: 0.05px;
      padding: 6px 10px;
      transition: all 0.32s ease;
      color: #fff;
      cursor: pointer;
      min-width: 44px;
      text-align: center;
    }
  `,
};
