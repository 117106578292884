/** @jsxImportSource @emotion/react */

import { Modal } from "components/common/Modal";
import { Footer } from "components/desktop/Footer";
import { Header } from "components/desktop/Header";
import Menu from "components/desktop/Menu";
import { Outlet } from "react-router-dom";

import { DefaultStyle } from "./index.style";

export const DefaultLayout = () => {
  return (
    <div css={DefaultStyle.index}>
      <div css={DefaultStyle.header}>
        <Header />
        <Menu />
      </div>
      <main css={DefaultStyle.mainContent}>
        <Outlet />
      </main>
      <Footer />
      <Modal />
    </div>
  );
};
