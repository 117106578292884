import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ModalUserPictureStyle = {
  wrapper: css`
    .ant-modal {
      &-content {
        padding: unset !important;
      }
      &-close {
        top: 4px;
        right: 4px;
        &-x {
          color: ${theme.colors.black};
        }
      }
    }
  `,

  mainContent: css`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 24px 24px 24px;

    button {
      width: 100%;
      height: 40px;
      background-color: #00d8a0;
      &:first-of-type {
        margin-top: 16px;
        background-color: #00d8a0 !important;
      }
      &:last-of-type {
        margin-top: 24px;
      }
    }
  `,

  title: css`
    color: ${theme.colors.black};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.15px;

    margin-top: 24px;
  `,

  inputFile: css`
    width: 100%;
    padding-top: 10px;
    span {
      font-size: 14px !important;
    }
    button {
      margin-top: unset !important;
    }
  `,

  avatar: css`
    width: 90px;
    height: 90px;
    margin-top: 8px;
    border-radius: 50%;

    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
};
